module.exports = {
  destinations: [
    {
      name: "Cameroon",
      slug: "/cameroon",
      saily: "https://go.saily.site/aff_c?offer_id=101&aff_id=5957&url_id=1344",
      meta: {
        category: "countries",
        parent: "Africa",
        tags: [],
        coords: {
          lat: 5.42406942,
          lon: 12.60858243,
        },
      },
      editorial: [
        {
          type: "p",
          children:
            "Cameroon celebrates the 65th anniversary of its independence in 2025 bringing overdue, well-deserved attention to the African nation’s extraordinary offerings. Pristine, unspoiled beaches of Kribi are more Castaway than Cancún, while cities Douala and Yaoundé pulse with an eclectic soundtrack drawn from the dance-heavy rumba rhythms of Central Africa.",
        },
        {
          type: "ol",
          children: [
            "Enjoy Kribi and its beautiful palm trees, waterfalls, white sands and fishing villages.",
            "Head to capital Yaoundé to check out Art Deco and African Modernist architecture.",
            "Go looking for mandrills, gorillas and forest elephants in Parc National de Campo-Ma’an.",
          ],
        },
        {
          type: "blockquote",
          children: [
            "You can’t be sad in Yaoundé, where the music never stops. And with music, there’s always dancing. Take a tour, make a friend, then get them to show you the Yaoundé night.",
            "Samuel Harouna, tour guide",
          ],
        },
      ],
      media: [
        {
          type: "image",
          orientation: "portrait",
          src: "https://lonelyplanetstatic.imgix.net/marketing/best-in-travel/2025/categories/countries/cameroon/tourists-paddling-boats-river-cameroon-portrait.jpg",
          alt: "Guides and tourists in wooden boats paddle down a scenic river",
          credit: "Fabian von Poser/Alamy",
        },
        {
          type: "image",
          orientation: "portrait",
          src: "https://lonelyplanetstatic.imgix.net/marketing/best-in-travel/2025/categories/countries/cameroon/forest-mountain-tropical-fog-clouds-portrait.jpg",
          alt: "An overhead view of a tropical forest with mountains and fog",
          credit: "Matthias Graben/Getty Images",
        },
        {
          type: "image",
          orientation: "portrait",
          src: "https://lonelyplanetstatic.imgix.net/marketing/best-in-travel/2025/categories/countries/cameroon/grass-roof-huts-sand-clouds-portrait.jpg",
          alt: "Two grass roof huts with doors on sand",
          credit: "Robert Harding/Adobe Stock",
        },
        {
          type: "image",
          orientation: "portrait",
          src: "https://lonelyplanetstatic.imgix.net/marketing/best-in-travel/2025/categories/countries/cameroon/spiral-building-architecture-blue-sky-portrait.jpg",
          alt: "Spiral building against blue sky with trees and stairs",
          credit: "Sid Mbongi/Shutterstock",
        },
        {
          type: "image",
          orientation: "portrait",
          src: "https://lonelyplanetstatic.imgix.net/marketing/best-in-travel/2025/categories/countries/cameroon/beach-tropical-palm-trees-rocks-portrait.jpg",
          alt: "A beach with tropical palm trees to the left and large rocks on the sand",
          credit: "Shutterstock",
        },
        {
          type: "image",
          src: "https://lonelyplanetstatic.imgix.net/marketing/best-in-travel/2025/categories/countries/cameroon/tourists-paddling-boats-river-cameroon.jpg",
          alt: "Guides and tourists in wooden boats paddle down a scenic river",
          credit: "Fabian von Poser/Alamy",
        },
        {
          type: "image",
          src: "https://lonelyplanetstatic.imgix.net/marketing/best-in-travel/2025/categories/countries/cameroon/forest-mountain-tropical-fog-clouds.jpg",
          alt: "An overhead view of a tropical forest with mountains and fog",
          credit: "Matthias Graben/Getty Images",
        },
        {
          type: "image",
          src: "https://lonelyplanetstatic.imgix.net/marketing/best-in-travel/2025/categories/countries/cameroon/grass-roof-huts-sand-clouds.jpg",
          alt: "Two grass roof huts with doors on sand",
          credit: "Robert Harding/Adobe Stock",
        },
        {
          type: "image",
          src: "https://lonelyplanetstatic.imgix.net/marketing/best-in-travel/2025/categories/countries/cameroon/spiral-building-architecture-blue-sky.jpg",
          alt: "Spiral building against blue sky with trees and stairs",
          credit: "Sid Mbongi/Shutterstock",
        },
        {
          type: "image",
          src: "https://lonelyplanetstatic.imgix.net/marketing/best-in-travel/2025/categories/countries/cameroon/beach-tropical-palm-trees-rocks.jpg",
          alt: "A beach with tropical palm trees to the left and large rocks on the sand",
          credit: "Shutterstock",
        },
      ],
      articles: ["/articles/first-time-guide-to-cameroon"],
    },
    {
      name: "Lithuania",
      slug: "/lithuania",
      saily: "https://go.saily.site/aff_c?offer_id=101&aff_id=5957&url_id=1344",
      meta: {
        category: "countries",
        parent: "Europe",
        tags: [],
        coords: {
          lat: 55.169438,
          lon: 23.881275,
        },
      },
      editorial: [
        {
          type: "p",
          children:
            'In a world that commodifies the terms "conservation"  and "sustainability", Lithuania epitomizes both. Named the European Green Capital of 2025, there’s never been a better time to explore its protected forests, lakes and sandy dunes along the Baltic Sea. Green spaces cover 61% of its capital, Vilnius, with unmissable attractions like the recently restored Sapieha Palace.',
        },
        {
          type: "ol",
          children: [
            "Toast Vilnius after dark with craft cocktails at Champaneria, followed by live music and dancing at Bardakas.",
            "Follow pilgrims to the emotive Hill of Crosses near Šiauliai, a powerful remembrance site made up of 100,000-plus crosses.",
            "Get lost in ancient oak forests and a labyrinth of wild boating lakes in the untouched Aukštaitija National Park.",
          ],
        },
        {
          type: "blockquote",
          children: [
            "Vilnius rocks with creativity, be it fine art galleries, trees dressed in knitting, local crafts or street musicians.",
            "Eglė Nutautaitė, Lithuanian novelist",
          ],
        },
      ],
      media: [
        {
          type: "image",
          orientation: "portrait",
          src: "https://lonelyplanetstatic.imgix.net/marketing/best-in-travel/2025/categories/countries/lithuania/aerial-bikers-cyclists-green.jpg",
          alt: "Aerial view of a peloton of bicyclists on an empty road with green hills",
          credit: "Getty Images",
        },
        {
          type: "image",
          orientation: "portrait",
          src: "https://lonelyplanetstatic.imgix.net/marketing/best-in-travel/2025/categories/countries/lithuania/trees-coast-waves.jpg",
          alt: "Aerial photo of a pine tree forest stopping abruptly at a cliff and waves",
          credit: "Amazing Aerial/Kintzing",
        },
        {
          type: "image",
          orientation: "portrait",
          src: "https://lonelyplanetstatic.imgix.net/marketing/best-in-travel/2025/categories/countries/lithuania/surfer-sand-snow.jpg",
          alt: "Aerial view of surfer standing in snow covered beach in sunny winter daytime.",
          credit: "Amazing Aerial/Kintzing",
        },
        {
          type: "image",
          orientation: "portrait",
          src: "https://lonelyplanetstatic.imgix.net/marketing/best-in-travel/2025/categories/countries/lithuania/aerial-city-walkway-snow-ice.jpg",
          alt: "Aerial view of a city with a snowy walkway over icy water",
          credit: "Gediminas Medziausis/Getty Images",
        },
        {
          type: "image",
          orientation: "portrait",
          src: "https://lonelyplanetstatic.imgix.net/marketing/best-in-travel/2025/categories/countries/lithuania/street-church-trees-lamppost.jpg",
          alt: "Street scene of people walking down a cobblestone street with trees and a church in the distance",
          credit: "Oleksandr Berezko/Shutterstock",
        },

        {
          type: "image",
          src: "https://lonelyplanetstatic.imgix.net/marketing/best-in-travel/2025/categories/countries/lithuania/aerial-bikers-cyclists-green.jpg",
          alt: "Aerial view of a peloton of bicyclists on an empty road with green hills",
          credit: "Getty Images",
        },
        {
          type: "image",
          src: "https://lonelyplanetstatic.imgix.net/marketing/best-in-travel/2025/categories/countries/lithuania/trees-coast-waves.jpg",
          alt: "Aerial photo of a pine tree forest stopping abruptly at a cliff and waves",
          credit: "Amazing Aerial/Kintzing",
        },
        {
          type: "image",
          src: "https://lonelyplanetstatic.imgix.net/marketing/best-in-travel/2025/categories/countries/lithuania/surfer-sand-snow.jpg",
          alt: "Aerial view of surfer standing in snow covered beach in sunny winter daytime.",
          credit: "Amazing Aerial/Kintzing",
        },
        {
          type: "image",
          src: "https://lonelyplanetstatic.imgix.net/marketing/best-in-travel/2025/categories/countries/lithuania/aerial-city-walkway-snow-ice.jpg",
          alt: "Aerial view of a city with a snowy walkway over icy water",
          credit: "Gediminas Medziausis/Getty Images",
        },
        {
          type: "image",
          src: "https://lonelyplanetstatic.imgix.net/marketing/best-in-travel/2025/categories/countries/lithuania/street-church-trees-lamppost.jpg",
          alt: "Street scene of people walking down a cobblestone street with trees and a church in the distance",
          credit: "Oleksandr Berezko/Shutterstock",
        },
      ],
      articles: [
        "/articles/guide-to-lithuania",
        "/articles/top-things-to-do-in-lithuania",
        "/articles/first-time-guide-to-vilnius",
      ],
    },
    {
      name: "Fiji",
      slug: "/fiji",
      saily: "https://go.saily.site/aff_c?offer_id=101&aff_id=5957&url_id=1344",
      meta: {
        category: "countries",
        parent: "Oceania",
        tags: [],
        coords: {
          lat: -17.21491787,
          lon: 178.6995091,
        },
      },
      editorial: [
        {
          type: "p",
          children:
            "Visiting Fiji is a transformative experience. This 330-island archipelago, surrounded by 1.3 million sq miles of cerulean waters, has 460 protected marine areas brimming with life. Protecting this delicate and fragile ecosystem, its heritage and natural resources, is at the heart of a new initiative that encourages preservation through activities like planting coral and mangroves.",
        },
        {
          type: "ol",
          children: [
            "Scuba dive or snorkel Fiji’s protected marine areas to explore its coral reefs.",
            "Enjoy Fijian hospitality through a cultural experience such as those offered by the sustainable tourism collective Duavata.",
            "Head to the country’s highlands and waterfalls for an authentic jungle and nature encounter.",
          ],
        },
        {
          type: "blockquote",
          children: [
            "Throughout Fiji, its culture, food and people epitomize the saying 'Fiji is the way the world should be.'",
            "Kitty Weatherall, manager, Jean-Michel Cousteau Resort",
          ],
        },
      ],
      media: [
        {
          type: "image",
          orientation: "portrait",
          src: "https://lonelyplanetstatic.imgix.net/marketing/best-in-travel/2025/categories/countries/fiji/surfer-jumping-boat-water-portrait.jpg",
          alt: "A surfer jumps off a boat holding a surfboard into the water",
          credit: "Alipate Laveti for ������ϲʼ�ʱ����",
        },
        {
          type: "image",
          src: "https://lonelyplanetstatic.imgix.net/marketing/best-in-travel/2025/categories/countries/fiji/surfer-jumping-boat-water.jpg",
          alt: "A surfer jumps off a boat holding a surfboard into the water",
          credit: "Alipate Laveti for ������ϲʼ�ʱ����",
        },
        {
          type: "image",
          orientation: "portrait",
          src: "https://lonelyplanetstatic.imgix.net/marketing/best-in-travel/2025/categories/countries/fiji/overhead-aerial-huts-hotel-water-portrait.jpg",
          alt: "An aerial view of huts at a resort surrounding a blue pool",
          credit: "Alipate Laveti for ������ϲʼ�ʱ����",
        },
        {
          type: "image",
          src: "https://lonelyplanetstatic.imgix.net/marketing/best-in-travel/2025/categories/countries/fiji/overhead-aerial-huts-hotel-water.jpg",
          alt: "An aerial view of huts at a resort surrounding a blue pool",
          credit: "Alipate Laveti for ������ϲʼ�ʱ����",
        },
        {
          type: "image",
          orientation: "portrait",
          src: "https://lonelyplanetstatic.imgix.net/marketing/best-in-travel/2025/categories/countries/fiji/woman-headdress-smiling-flowers-portrait.jpg",
          alt: "A woman in a headdress smiling and holding her hands in front of her",
          credit: "Alipate Laveti for ������ϲʼ�ʱ����",
        },
        {
          type: "image",
          src: "https://lonelyplanetstatic.imgix.net/marketing/best-in-travel/2025/categories/countries/fiji/woman-headdress-smiling-flowers.jpg",
          alt: "A woman in a headdress smiling and holding her hands in front of her",
          credit: "Alipate Laveti for ������ϲʼ�ʱ����",
        },
        {
          type: "image",
          orientation: "portrait",
          src: "https://lonelyplanetstatic.imgix.net/marketing/best-in-travel/2025/categories/countries/fiji/fish-hands-palm-tree-leaf-portrait.jpg",
          alt: "Two hands cutting open a fish on a palm leaf",
          credit: "Alipate Laveti for ������ϲʼ�ʱ����",
        },
        {
          type: "image",
          src: "https://lonelyplanetstatic.imgix.net/marketing/best-in-travel/2025/categories/countries/fiji/fish-hands-palm-tree-leaf.jpg",
          alt: "Two hands cutting open a fish on a palm leaf",
          credit: "Alipate Laveti for ������ϲʼ�ʱ����",
        },
        {
          type: "image",
          orientation: "portrait",
          src: "https://lonelyplanetstatic.imgix.net/marketing/best-in-travel/2025/categories/countries/fiji/boy-bicycle-palm-trees-road-portrait.jpg",
          alt: "A boy cycling on a road in between palm trees",
          credit: "Alipate Laveti for ������ϲʼ�ʱ����",
        },
        {
          type: "image",
          src: "https://lonelyplanetstatic.imgix.net/marketing/best-in-travel/2025/categories/countries/fiji/boy-bicycle-palm-trees-road.jpg",
          alt: "A boy cycling on a road in between palm trees",
          credit: "Alipate Laveti for ������ϲʼ�ʱ����",
        },
      ],
    },
    {
      name: "Laos",
      slug: "/laos",
      saily: false,
      meta: {
        category: "countries",
        parent: "Asia",
        tags: [],
        coords: {
          lat: 18.35175207,
          lon: 103.9932617,
        },
      },
      editorial: [
        {
          type: "p",
          children:
            "Take a ride on Laos newly-launched, high-speed international rail connecting its sedate mountainous plains with China’s bustling southwestern Yunnan Province. Travelers now have an affordable, efficient and sustainable way to traverse the region's best, yet relatively unseen, cultural and natural wonders and ancient cities. This is a quieter side of Asia less visited by foreign tourists.",
        },
        {
          type: "ol",
          children: [
            "Pick your favorite adrenaline-fueled adventure amongst the picturesque rivers and mountains of Vang Vieng where biking, ziplining, tubing, rock climbing and trekking await.",
            "Visit Vientiane’s Pha That Luang temple and Patuxai, a recreation of France’s Arc de Triomphe.",
            "Disembark at Luang Prabang for day trips to Kuang Si Waterfall and the Old Quarter, and to join a sunrise alms-giving ceremony.",
          ],
        },
        {
          type: "blockquote",
          children: [
            "Before the train, buses between the main towns could take all day. Now it’s an hour or less, which means more time to explore.",
            "Chris Schalkx, Journalist",
          ],
        },
      ],
      media: [
        {
          type: "image",
          orientation: "portrait",
          src: "https://lonelyplanetstatic.imgix.net/marketing/best-in-travel/2025/categories/countries/laos/tree-pool-people-swimming-portrait.jpg",
          alt: "People swimming in a blue natural pool with trees overhead and rocks on the shore",
          credit: "Alamy",
        },
        {
          type: "image",
          src: "https://lonelyplanetstatic.imgix.net/marketing/best-in-travel/2025/categories/countries/laos/tree-pool-people-swimming.jpg",
          alt: "People swimming in a blue natural pool with trees overhead and rocks on the shore",
          credit: "Alamy",
        },
        {
          type: "image",
          orientation: "portrait",
          src: "https://lonelyplanetstatic.imgix.net/marketing/best-in-travel/2025/categories/countries/laos/boys-river-mountains-tropical-portrait.jpg",
          alt: "Boys throw rocks into a river on a beach in between mountains and tropical trees",
          credit: "Giulia Verdinelli",
        },
        {
          type: "image",
          src: "https://lonelyplanetstatic.imgix.net/marketing/best-in-travel/2025/categories/countries/laos/boys-river-mountains-tropical.jpg",
          alt: "Boys throw rocks into a river on a beach in between mountains and tropical trees",
          credit: "Giulia Verdinelli",
        },
        {
          type: "image",
          orientation: "portrait",
          src: "https://lonelyplanetstatic.imgix.net/marketing/best-in-travel/2025/categories/countries/laos/temple-man-sleeping-portrait.jpg",
          alt: "A man sleeps in an ornately decorated temple with a basket beside him",
          credit: "Giulia Verdinelli",
        },
        {
          type: "image",
          src: "https://lonelyplanetstatic.imgix.net/marketing/best-in-travel/2025/categories/countries/laos/temple-man-sleeping.jpg",
          alt: "A man sleeps in an ornately decorated temple with a basket beside him",
          credit: "Giulia Verdinelli",
        },
        {
          type: "image",
          orientation: "portrait",
          src: "https://lonelyplanetstatic.imgix.net/marketing/best-in-travel/2025/categories/countries/laos/people-couple-train-view-portrait.jpg",
          alt: "People in seats on a train with a river and brush in the window behind them",
          credit: "Lauren DeCicca/Getty Images",
        },
        {
          type: "image",
          src: "https://lonelyplanetstatic.imgix.net/marketing/best-in-travel/2025/categories/countries/laos/people-couple-train-view.jpg",
          alt: "People in seats on a train with a river and brush in the window behind them",
          credit: "Lauren DeCicca/Getty Images",
        },
        {
          type: "image",
          orientation: "portrait",
          src: "https://lonelyplanetstatic.imgix.net/marketing/best-in-travel/2025/categories/countries/laos/boat-river-palm-tree-sunset-portrait.jpg",
          alt: "A boat drives down a river at sunset with palm trees",
          credit: "Sarah Rainer/Kintzing",
        },
        {
          type: "image",
          src: "https://lonelyplanetstatic.imgix.net/marketing/best-in-travel/2025/categories/countries/laos/boat-river-palm-tree-sunset.jpg",
          alt: "A boat drives down a river at sunset with palm trees",
          credit: "Sarah Rainer/Kintzing",
        },
      ],
      articles: [
        "/articles/guide-to-laos",
        "/articles/best-places-to-visit-in-laos",
      ],
    },
    {
      name: "Kazakhstan",
      slug: "/kazakhstan",
      saily: "https://go.saily.site/aff_c?offer_id=101&aff_id=5957&url_id=1344",
      meta: {
        category: "countries",
        parent: "Asia",
        tags: [],
        coords: {
          lat: 48.29853629,
          lon: 67.24577294,
        },
      },
      editorial: [
        {
          type: "p",
          children:
            "Kazakhstan is an immersive destination for travelers in search of an unspoiled adventure awash in natural beauty, history and culture. Hikers will love the many trails in Sairam-Ugam National Park, uncrowded by tourists. Visit Shymkent, known for some of the country’s best food, or see Almaty, the old capital, for Kazakhstan’s Soviet heritage.",
        },
        {
          type: "ol",
          children: [
            "Dine at Auyl, outside Almaty, where design, history and neo-nomad cuisine meet.",
            "Visit the singing dunes in Altyn-Emel National Park and hear the organ-like hum emitted from the sands and the wind.",
            "Try beshbarmak, a celebratory dish made with horse or mutton at restaurant Bozjyra in Shymkent.",
          ],
        },
        {
          type: "blockquote",
          children: [
            "Almaty to me means mountain peaks that surround the city. Surrounded by Tian Shan spruce trees, on horseback or on foot, there is nowhere else I feel like going any time of year.",
            "Anely Nazarbek, outdoors enthusiast",
          ],
        },
      ],
      media: [
        {
          type: "video",
          orientation: "landscape",
          credit: "Best In Travel 2025",
          src: {
            poster:
              "https://lonelyplanetstatic.imgix.net/marketing/best-in-travel/2025/videos/kazakhstan/kazakhstan-trailer-landscape.png",
            mp4: "https://lonelyplanetstatic.imgix.net/marketing/best-in-travel/2025/videos/kazakhstan/kazakhstan-trailer-landscape.mp4",
            webm: "https://lonelyplanetstatic.imgix.net/marketing/best-in-travel/2025/videos/kazakhstan/kazakhstan-trailer-landscape.webm",
            jwvid: "Sp1f6cSN",
          },
        },
        {
          type: "video",
          orientation: "portrait",
          credit: "Best In Travel 2025",
          src: {
            poster:
              "https://lonelyplanetstatic.imgix.net/marketing/best-in-travel/2025/videos/kazakhstan/kazakhstan-trailer-portrait.png",
            mp4: "https://lonelyplanetstatic.imgix.net/marketing/best-in-travel/2025/videos/kazakhstan/kazakhstan-trailer-portrait.mp4",
            webm: "https://lonelyplanetstatic.imgix.net/marketing/best-in-travel/2025/videos/kazakhstan/kazakhstan-trailer-portrait.webm",
            jwvid: "oQFN0xGZ",
          },
        },
        {
          type: "image",
          orientation: "portrait",
          src: "https://lonelyplanetstatic.imgix.net/marketing/best-in-travel/2025/categories/countries/kazakhstan/families-children-picnic-grass-blanket-horses-mountains-portrait.jpg",
          alt: "Families and pets gathered on picnic blankets with tables of food, mountains, horses",
          credit: "Alamy",
        },
        {
          type: "image",
          src: "https://lonelyplanetstatic.imgix.net/marketing/best-in-travel/2025/categories/countries/kazakhstan/families-children-picnic-grass-blanket-horses-mountains.jpg",
          alt: "Families and pets gathered on picnic blankets with tables of food, mountains, horses",
          credit: "Alamy",
        },
        {
          type: "image",
          orientation: "portrait",
          src: "https://lonelyplanetstatic.imgix.net/marketing/best-in-travel/2025/categories/countries/kazakhstan/desert-dunes-sky-portrait.jpg",
          alt: "A view of desert sand dunes and blue sky",
          credit: "Sembi Productions for ������ϲʼ�ʱ����",
        },
        {
          type: "image",
          src: "https://lonelyplanetstatic.imgix.net/marketing/best-in-travel/2025/categories/countries/kazakhstan/desert-dunes-sky.jpg",
          alt: "A view of desert sand dunes and blue sky",
          credit: "Sembi Productions for ������ϲʼ�ʱ����",
        },
        {
          type: "image",
          orientation: "portrait",
          src: "https://lonelyplanetstatic.imgix.net/marketing/best-in-travel/2025/categories/countries/kazakhstan/globe-building-sky-portrait.jpg",
          alt: "A shot of a globe shaped building seen through the latticework of another architectural structure",
          credit: "Sembi Productions for ������ϲʼ�ʱ����",
        },
        {
          type: "image",
          src: "https://lonelyplanetstatic.imgix.net/marketing/best-in-travel/2025/categories/countries/kazakhstan/globe-building-sky.jpg",
          alt: "A shot of a globe shaped building seen through the latticework of another architectural structure",
          credit: "Sembi Productions for ������ϲʼ�ʱ����",
        },
        {
          type: "image",
          orientation: "portrait",
          src: "https://lonelyplanetstatic.imgix.net/marketing/best-in-travel/2025/categories/countries/kazakhstan/food-plates-table-tablecloth-portrait.jpg",
          alt: "An overhead shot of food and empty plates on a set table",
          credit: "Sembi Productions for ������ϲʼ�ʱ����",
        },
        {
          type: "image",
          src: "https://lonelyplanetstatic.imgix.net/marketing/best-in-travel/2025/categories/countries/kazakhstan/food-plates-table-tablecloth.jpg",
          alt: "An overhead shot of food and empty plates on a set table",
          credit: "Sembi Productions for ������ϲʼ�ʱ����",
        },
        {
          type: "image",
          orientation: "portrait",
          src: "https://lonelyplanetstatic.imgix.net/marketing/best-in-travel/2025/categories/countries/kazakhstan/men-fruit-geometric-market-stalls-portrait.jpg",
          alt: "A shot of fruit and nut sellers in a market with their wares laid out geometrically",
          credit: "Jane Sweeney/AWL Images",
        },
        {
          type: "image",
          src: "https://lonelyplanetstatic.imgix.net/marketing/best-in-travel/2025/categories/countries/kazakhstan/men-fruit-geometric-market-stalls.jpg",
          alt: "A shot of fruit and nut sellers in a market with their wares laid out geometrically",
          credit: "Jane Sweeney/AWL Images",
        },
      ],
      articles: [
        "/articles/top-things-to-do-in-kazakhstan",
        "/articles/best-time-to-visit-kazakhstan",
        "/articles/getting-around-kazakhstan",
      ],
    },
    {
      name: "Paraguay",
      slug: "/paraguay",
      saily: "https://go.saily.site/aff_c?offer_id=101&aff_id=5957&url_id=1344",
      meta: {
        category: "countries",
        parent: "South America",
        tags: [],
        coords: {
          lat: -23.26111642,
          lon: -58.53342751,
        },
      },
      editorial: [
        {
          type: "p",
          children:
            "In the heart of South America, uncrowded Paraguay teems with life of all sorts and has a laid-back, welcoming vibe. There’s plenty to see. Visit the spiky Chaco forest (with jaguars and giant anteaters roaming around) or go to picturesque pueblos and plunging waterfalls. Be sure to tour the eclectic architecture of the charming capital, Asunción.",
        },
        {
          type: "ol",
          children: [
            "Sunbathe on the golden beaches of Carmen del Paraná.",
            "Voyage upriver to the Pantanal, the world’s largest tropical wetland, aboard the Siete Cabrillas floating hotel from Concepción.",
            "Pogo with chanting football fans at the Superclásico showdown between top clubs Olimpia and Cerro Porteño.",
          ],
        },
        {
          type: "blockquote",
          children: [
            "Paraguay is like a village stopped in time, where we still preserve a sense of communal living. La Chacarita is the essence, the genesis of Paraguay: everything started there.",
            "Christian Nuñez, founder, Chacatours",
          ],
        },
      ],
      media: [
        {
          type: "image",
          orientation: "portrait",
          src: "https://lonelyplanetstatic.imgix.net/marketing/best-in-travel/2025/categories/countries/paraguay/people-eating-lunch-in-town-square-portrait.jpg",
          alt: "A group of five people eating on a terrace overlooking a square with a building",
          credit: "María Magdalena Arréllaga for ������ϲʼ�ʱ����",
        },
        {
          type: "image",
          src: "https://lonelyplanetstatic.imgix.net/marketing/best-in-travel/2025/categories/countries/paraguay/people-eating-lunch-in-town-square.jpg",
          alt: "A group of five people eating on a terrace overlooking a square with a building",
          credit: "María Magdalena Arréllaga for ������ϲʼ�ʱ����",
        },
        {
          type: "image",
          orientation: "portrait",
          src: "https://lonelyplanetstatic.imgix.net/marketing/best-in-travel/2025/categories/countries/paraguay/women-dancing-town-square-portrait.jpg",
          alt: "A group of older women in elaborate dress dancing",
          credit: "María Magdalena Arréllaga for ������ϲʼ�ʱ����",
        },
        {
          type: "image",
          src: "https://lonelyplanetstatic.imgix.net/marketing/best-in-travel/2025/categories/countries/paraguay/women-dancing-town-square.jpg",
          alt: "A group of older women in elaborate dress dancing",
          credit: "María Magdalena Arréllaga for ������ϲʼ�ʱ����",
        },
        {
          type: "image",
          orientation: "portrait",
          src: "https://lonelyplanetstatic.imgix.net/marketing/best-in-travel/2025/categories/countries/paraguay/two-people-brick-buildings-blue-door-portrait.jpg",
          alt: "Two people walk down a brick pathway to a building with art installations",
          credit: "María Magdalena Arréllaga for ������ϲʼ�ʱ����",
        },
        {
          type: "image",
          src: "https://lonelyplanetstatic.imgix.net/marketing/best-in-travel/2025/categories/countries/paraguay/two-people-brick-buildings-blue-door.jpg",
          alt: "Two people walk down a brick pathway to a building with art installations",
          credit: "María Magdalena Arréllaga for ������ϲʼ�ʱ����",
        },
        {
          type: "image",
          orientation: "portrait",
          src: "https://lonelyplanetstatic.imgix.net/marketing/best-in-travel/2025/categories/countries/paraguay/man-boat-river-sky-portrait.jpg",
          alt: "A man with a pole steers a boat on a river reflecting the sky",
          credit: "María Magdalena Arréllaga for ������ϲʼ�ʱ����",
        },
        {
          type: "image",
          src: "https://lonelyplanetstatic.imgix.net/marketing/best-in-travel/2025/categories/countries/paraguay/man-boat-river-sky.jpg",
          alt: "A man with a pole steers a boat on a river reflecting the sky",
          credit: "María Magdalena Arréllaga for ������ϲʼ�ʱ����",
        },
        {
          type: "image",
          orientation: "portrait",
          src: "https://lonelyplanetstatic.imgix.net/marketing/best-in-travel/2025/categories/countries/paraguay/two-alligators-river-swamp-portrait.jpg",
          alt: "Two alligators on the banks of a river, swamp",
          credit: "María Magdalena Arréllaga for ������ϲʼ�ʱ����",
        },
        {
          type: "image",
          src: "https://lonelyplanetstatic.imgix.net/marketing/best-in-travel/2025/categories/countries/paraguay/two-alligators-river-swamp.jpg",
          alt: "Two alligators on the banks of a river, swamp",
          credit: "María Magdalena Arréllaga for ������ϲʼ�ʱ����",
        },
      ],
      articles: ["/articles/guide-to-paraguay"], // TODO - add articles
    },
    {
      name: "Trinidad & Tobago",
      slug: "/trinidad-and-tobago",
      saily: false,
      meta: {
        category: "countries",
        parent: "Caribbean",
        tags: [],
        coords: {
          lat: 10.3994561,
          lon: -61.37674729,
        },
      },
      editorial: [
        {
          type: "p",
          children:
            "This twin-island Caribbean nation's food scene is a panoply of flavors with African, East Asian, French, Spanish, English, Dutch, Chinese and Indigenous influences. Trinidad and Tobago's headliner, though, is Carnival. This months-long extravaganza, which features steelpan competitions, stick-fighting battles and calypso showdowns, culminates in vibrant costume parades that take place on March 3 and 4, 2025.",
        },
        {
          type: "ol",
          children: [
            "Go birding, hiking and turtle-watching at the Hadco Experiences Asa Wright Nature Centre.",
            "Enjoy Tobago’s water sports and popular food spots at Pigeon Point Beach. Try the delectable curried crab and dumpling at nearby Miss Trim’s.",
            "Check out the sacred Hindu pantheon, Temple in the Sea, a colorful, octagonal-shaped structure that sits on a causeway in the Gulf of Paria.",
          ],
        },
        {
          type: "blockquote",
          children: [
            "In T&T, we have the biggest melting pot of cultures, resulting in the most amazing food. From fancy restaurants to street food, it’s all a celebration of who we are.",
            "Brigette Joseph, culinary and hospitality consultant",
          ],
        },
      ],
      media: [
        {
          type: "video",
          orientation: "landscape",
          credit: "Best In Travel 2025",
          src: {
            poster:
              "https://lonelyplanetstatic.imgix.net/marketing/best-in-travel/2025/videos/trinidad-and-tobago/trinidad-and-tobago-trailer-landscape.png",
            mp4: "https://lonelyplanetstatic.imgix.net/marketing/best-in-travel/2025/videos/trinidad-and-tobago/trinidad-and-tobago-trailer-landscape.mp4",
            webm: "https://lonelyplanetstatic.imgix.net/marketing/best-in-travel/2025/videos/trinidad-and-tobago/trinidad-and-tobago-trailer-landscape.webm",
            jwvid: "VxTAnQCE",
          },
        },
        {
          type: "video",
          orientation: "portrait",
          credit: "Best In Travel 2025",
          src: {
            poster:
              "https://lonelyplanetstatic.imgix.net/marketing/best-in-travel/2025/videos/trinidad-and-tobago/trinidad-and-tobago-trailer-portrait.png",
            mp4: "https://lonelyplanetstatic.imgix.net/marketing/best-in-travel/2025/videos/trinidad-and-tobago/trinidad-and-tobago-trailer-portrait.mp4",
            webm: "https://lonelyplanetstatic.imgix.net/marketing/best-in-travel/2025/videos/trinidad-and-tobago/trinidad-and-tobago-trailer-portrait.webm",
            jwvid: "wQ1hwweD",
          },
        },
        {
          type: "image",
          src: "https://lonelyplanetstatic.imgix.net/marketing/best-in-travel/2025/categories/countries/trinidad-and-tobago/beach-aerial-boats-trees-sky.jpg",
          credit: "Jonathan S Gift for ������ϲʼ�ʱ����",
          alt: "A beach scene with boats in a cove with blue water and trees all around",
        },
        {
          orientation: "portrait",
          type: "image",
          src: "https://lonelyplanetstatic.imgix.net/marketing/best-in-travel/2025/categories/countries/trinidad-and-tobago/beach-aerial-boats-trees-sky-potrait.jpg",
          credit: "Jonathan S Gift for ������ϲʼ�ʱ����",
          alt: "A beach scene with boats in a cove with blue water and trees all around",
        },
        {
          type: "image",
          src: "https://lonelyplanetstatic.imgix.net/marketing/best-in-travel/2025/categories/countries/trinidad-and-tobago/men-flags-stilts-flag-trinidad-and-tobago.jpg",
          credit: "Jonathan S Gift for ������ϲʼ�ʱ����",
          alt: "Five men with stilts and hats and costumes with one man on the ground in the venter holding a flag",
        },
        {
          orientation: "portrait",
          type: "image",
          src: "https://lonelyplanetstatic.imgix.net/marketing/best-in-travel/2025/categories/countries/trinidad-and-tobago/men-flags-stilts-flag-trinidad-and-tobago-potrait.jpg",
          credit: "Jonathan S Gift for ������ϲʼ�ʱ����",
          alt: "Five men with stilts and hats and costumes with one man on the ground in the venter holding a flag",
        },
        {
          type: "image",
          src: "https://lonelyplanetstatic.imgix.net/marketing/best-in-travel/2025/categories/countries/trinidad-and-tobago/man-woman-lounge-beach-palm-tree.jpg",
          credit: "Jonathan S Gift for ������ϲʼ�ʱ����",
          alt: "A man and a woman in bathing suits sit on striped towels lounging on chairs underneath an umbrella on the beach",
        },
        {
          orientation: "portrait",
          type: "image",
          src: "https://lonelyplanetstatic.imgix.net/marketing/best-in-travel/2025/categories/countries/trinidad-and-tobago/man-red-black-stripes-building-costume-potrait.jpg",
          credit: "Jonathan S Gift for ������ϲʼ�ʱ����",
          alt: "A man and a woman in bathing suits sit on striped towels lounging on chairs underneath an umbrella on the beach",
        },
        {
          type: "image",
          src: "https://lonelyplanetstatic.imgix.net/marketing/best-in-travel/2025/categories/countries/trinidad-and-tobago/boat-birds-water-sky.jpg",
          credit: "Jonathan S Gift for ������ϲʼ�ʱ����",
          alt: "Two boats in the water with birds on them and mountains in the distance",
        },
        {
          orientation: "portrait",
          type: "image",
          src: "https://lonelyplanetstatic.imgix.net/marketing/best-in-travel/2025/categories/countries/trinidad-and-tobago/beach-aerial-boats-trees-sky-potrait.jpg",
          credit: "Jonathan S Gift for ������ϲʼ�ʱ����",
          alt: "Two boats in the water with birds on them and mountains in the distance",
        },
      ],
      articles: [
        "/articles/guide-to-trinidad-and-tobago",
        "/articles/caribbean-travel-hurricane-season",
        "/articles/latin-american-and-caribbean-carnivals-for-every-traveler",
      ],
    },
    {
      name: "Vanuatu",
      slug: "/vanuatu",
      saily: "https://go.saily.site/aff_c?offer_id=101&aff_id=5957&url_id=1344",
      meta: {
        category: "countries",
        parent: "Oceania",
        tags: [],
        coords: {
          lat: -15.70635694,
          lon: 166.9185384,
        },
      },
      editorial: [
        {
          type: "p",
          children:
            "With fewer than 45,000 tourists a year, Vanuatu is a largely untouched island escape, swathed in jungle, fringed by deserted beaches, dotted with volcanoes and laced with scenic trails. But the best reason to visit is to experience the rich heritage and diverse cultures of the Ni-Vanuatu (Melanesian people of Vanuatu, also known as Ni-Van).",
        },
        {
          type: "ol",
          children: [
            "Stand on the rim of Tanna Island’s Yasur volcano as lava spurts into the air.",
            "Sample Vanuatu’s signature beverage, kava, at a local bar on any island.",
            "Scuba dive famous wrecks and coral reefs, or splash around in Efate Island’s idyllic Blue Lagoon and Mele Cascades.",
          ],
        },
        {
          type: "blockquote",
          children: [
            "I love shopping for fruits and vegetables at Port Vila Market, as they’re organically grown and so fresh. I also often buy some manioc rolls (cassava dough stuffed with meat); they’re my favorite!",
            "Beverly Teahiu, local tour guide",
          ],
        },
      ],
      media: [
        {
          type: "video",
          orientation: "landscape",
          credit: "Best In Travel 2025",
          src: {
            poster:
              "https://lonelyplanetstatic.imgix.net/marketing/best-in-travel/2025/videos/vanuatu/vanuatu-trailer-landscape.png",
            mp4: "https://lonelyplanetstatic.imgix.net/marketing/best-in-travel/2025/videos/vanuatu/vanuatu-trailer-landscape.mp4",
            webm: "https://lonelyplanetstatic.imgix.net/marketing/best-in-travel/2025/videos/vanuatu/vanuatu-trailer-landscape.webm",
            jwvid: "bnbV7NIR",
          },
        },
        {
          type: "video",
          orientation: "portrait",
          credit: "Best In Travel 2025",
          src: {
            poster:
              "https://lonelyplanetstatic.imgix.net/marketing/best-in-travel/2025/videos/vanuatu/vanuatu-trailer-portrait.png",
            mp4: "https://lonelyplanetstatic.imgix.net/marketing/best-in-travel/2025/videos/vanuatu/vanuatu-trailer-portrait.mp4",
            webm: "https://lonelyplanetstatic.imgix.net/marketing/best-in-travel/2025/videos/vanuatu/vanuatu-trailer-portrait.webm",
            jwvid: "e2VW8Tof",
          },
        },
        {
          type: "image",
          orientation: "portrait",
          src: "https://lonelyplanetstatic.imgix.net/marketing/best-in-travel/2025/categories/countries/vanuatu/swimmer-in-blue-water-aerial-portrait.jpg",
          alt: "Aerial of swimmer in blue water surrounded by trees",
          credit: "Damian Mobbs for ������ϲʼ�ʱ����",
        },
        {
          type: "image",
          src: "https://lonelyplanetstatic.imgix.net/marketing/best-in-travel/2025/categories/countries/vanuatu/swimmer-in-blue-water-aerial.jpg",
          alt: "Aerial of swimmer in blue water surrounded by trees",
          credit: "Damian Mobbs for ������ϲʼ�ʱ����",
        },
        {
          type: "image",
          orientation: "portrait",
          src: "https://lonelyplanetstatic.imgix.net/marketing/best-in-travel/2025/categories/countries/vanuatu/woman-in-orange-mountain-landscape-portrait.jpg",
          alt: "Woman in orange traditional dress in front of volcano landscape",
          credit: "Damian Mobbs for ������ϲʼ�ʱ����",
        },
        {
          type: "image",
          src: "https://lonelyplanetstatic.imgix.net/marketing/best-in-travel/2025/categories/countries/vanuatu/woman-in-orange-mountain-landscape.jpg",
          alt: "Woman in orange traditional dress in front of volcano landscape",
          credit: "Damian Mobbs for ������ϲʼ�ʱ����",
        },
        {
          type: "image",
          orientation: "portrait",
          src: "https://lonelyplanetstatic.imgix.net/marketing/best-in-travel/2025/categories/countries/vanuatu/group-of-dancers-grass-skirts-tropical-portrait.jpg",
          alt: "Group of dancers in grass skirts with volcano landscape",
          credit: "Damian Mobbs for ������ϲʼ�ʱ����",
        },
        {
          type: "image",
          src: "https://lonelyplanetstatic.imgix.net/marketing/best-in-travel/2025/categories/countries/vanuatu/group-of-dancers-grass-skirts-tropical.jpg",
          alt: "Group of dancers in grass skirts with volcano landscape",
          credit: "Damian Mobbs for ������ϲʼ�ʱ����",
        },
        {
          type: "image",
          orientation: "portrait",
          src: "https://lonelyplanetstatic.imgix.net/marketing/best-in-travel/2025/categories/countries/vanuatu/woman-swinging-tree-beach-portrait.jpg",
          alt: "A tree limb overhanging a beach with water and a woman in a swing",
          credit: "Damian Mobbs for ������ϲʼ�ʱ����",
        },
        {
          type: "image",
          src: "https://lonelyplanetstatic.imgix.net/marketing/best-in-travel/2025/categories/countries/vanuatu/woman-swinging-tree-beach.jpg",
          alt: "A tree limb overhanging a beach with water and a woman in a swing",
          credit: "Damian Mobbs for ������ϲʼ�ʱ����",
        },
      ],
      articles: [
        "/articles/guide-to-vanuatu",
        "/articles/best-places-to-visit-in-vanuatu",
        "/articles/guide-to-vanuatu-snorkel-scuba",
      ],
    },
    {
      name: "Slovakia",
      slug: "/slovakia",
      saily: "https://go.saily.site/aff_c?offer_id=101&aff_id=5957&url_id=1344",
      meta: {
        category: "countries",
        parent: "Europe",
        tags: [],
        coords: {
          lat: 48.69143112,
          lon: 19.55225861,
        },
      },
      editorial: [
        {
          type: "p",
          children:
            "Now is the time to visit Slovakia, thanks to a focus on outdoor adventures, ecotourism and multiple ongoing restoration projects in the capital and beyond. Take in the cultural riches and history in Bratislava, traverse the beech forests and wildflower meadows that cascade down the flanks of the Carpathian Mountains or hop aboard the Tatra Electric Railway.",
        },
        {
          type: "ol",
          children: [
            "Admire Brutalist architecture with Bratislava’s topsy-turvy Slovak National Radio Building or Banská Bystrica’s sci-fi domed Museum of the Slovak National Uprising.",
            "Go snow-shoeing in the High Tatras or scale ladders above thrashing waterfalls in Slovenský Raj National Park.",
            "See underground wonders including Europe’s largest stalagmite inside Krásnohorská Cave and explore the shimmering perma-freeze of Dobšinská Ice Cave.",
          ],
        },
        {
          type: "blockquote",
          children: [
            "During the ’70s, there were a lot of utopian projects, most of which were never finished. Architecture like Bratislava’s UFO building marks the space-race era, where everything was supposed to be possible.",
            "Brano Chrenka, co-founder, Authentic Slovakia tours",
          ],
        },
      ],
      media: [
        {
          type: "image",
          orientation: "portrait",
          src: "https://lonelyplanetstatic.imgix.net/marketing/best-in-travel/2025/categories/countries/slovakia/church-town-hills-clouds-portrait.jpg",
          alt: "A church in a town with greenery and blue skies",
          credit: "Getty Images",
        },
        {
          type: "image",
          src: "https://lonelyplanetstatic.imgix.net/marketing/best-in-travel/2025/categories/countries/slovakia/church-town-hills-clouds.jpg",
          alt: "A church in a town with greenery and blue skies",
          credit: "Getty Images",
        },
        {
          type: "image",
          orientation: "portrait",
          src: "https://lonelyplanetstatic.imgix.net/marketing/best-in-travel/2025/categories/countries/slovakia/train-cows-bridge-portrait.jpg",
          alt: "A train going over an archway bridge with cows on the grass in the foreground",
          credit: "iStock",
        },
        {
          type: "image",
          src: "https://lonelyplanetstatic.imgix.net/marketing/best-in-travel/2025/categories/countries/slovakia/train-cows-bridge.jpg",
          alt: "A train going over an archway bridge with cows on the grass in the foreground",
          credit: "iStock",
        },
        {
          type: "image",
          orientation: "portrait",
          src: "https://lonelyplanetstatic.imgix.net/marketing/best-in-travel/2025/categories/countries/slovakia/woman-hot-tub-swim-cap-nature-portrait.jpg",
          alt: "A woman in an orange bathing cap sits in a natural pool surrounded by rocks",
          credit: "Shutterstock",
        },
        {
          type: "image",
          src: "https://lonelyplanetstatic.imgix.net/marketing/best-in-travel/2025/categories/countries/slovakia/woman-hot-tub-swim-cap-nature.jpg",
          alt: "A woman in an orange bathing cap sits in a natural pool surrounded by rocks",
          credit: "Shutterstock",
        },
        {
          _comment: "#4 mobile",
          type: "image",
          orientation: "portrait",
          src: "https://lonelyplanetstatic.imgix.net/marketing/best-in-travel/2025/categories/countries/slovakia/woman-bicycle-street-building-mural-portrait.jpg",
          alt: "A woman on a bicycle walks on a street in front of an old building with a mural",
          credit: "Ian Trower/AWL Images",
        },
        {
          _comment: "#4 desktop",
          type: "image",
          src: "https://lonelyplanetstatic.imgix.net/marketing/best-in-travel/2025/categories/countries/slovakia/castle-hill-green.jpg",
          alt: "A castle on a green hill with mountains and sky behind it",
          credit: "Shutterstock",
        },
        {
          type: "image",
          orientation: "portrait",
          src: "https://lonelyplanetstatic.imgix.net/marketing/best-in-travel/2025/categories/countries/slovakia/city-town-village-people-cafe-portrait.jpg",
          alt: "A corner with buildings and people milling around on the sidewalk, cafes",
          credit: "Shutterstock",
        },
        {
          type: "image",
          src: "https://lonelyplanetstatic.imgix.net/marketing/best-in-travel/2025/categories/countries/slovakia/city-town-village-people-cafe.jpg",
          alt: "A corner with buildings and people milling around on the sidewalk, cafes",
          credit: "Shutterstock",
        },
      ],
      articles: [
        "/articles/best-time-to-visit-slovakia",
        "/articles/best-places-to-visit-in-slovakia",
        "/articles/top-things-to-do-in-slovakia",
        "/articles/what-to-eat-and-drink-in-slovakia",
      ],
    },
    {
      name: "Armenia",
      slug: "/armenia",
      saily: "https://go.saily.site/aff_c?offer_id=101&aff_id=5957&url_id=1344",
      meta: {
        category: "countries",
        parent: "Europe",
        tags: [],
        coords: {
          lat: 40.40537313,
          lon: 44.96329632,
        },
      },
      editorial: [
        {
          type: "p",
          children:
            "From a mass tourism perspective, Armenia remains relatively undiscovered – for now. This is the time to visit, before everyone else gets wise. See the South Caucasus, stunning monasteries, the new Armenian National Trail or simply savor a glass of Areni Noir in a vineyard – Armenia's wine scene is on par with any in the world.",
        },
        {
          type: "ol",
          children: [
            "Visit UNESCO-recognized monasteries Geghard, Haghpat and Sanahin, as well as 2000-year-old Garni Temple.",
            "Feast on traditional dishes like tolma (beef-stuffed vine leaves), ghapama (stuffed pumpkin), lavash (flatbread) and khorovats (grilled meat) at Lavash in Yerevan.",
            "Stroll through wildflowers in springtime and visit the finely restored Haghartsin Monastery on hikes around Dilijan.",
          ],
        },
        {
          type: "blockquote",
          children: [
            "Living in Armenia is a joy thanks to its vibrant culture and breathtaking landscapes. When visiting, eat delicious tolma, explore ancient monasteries and enjoy Yerevan’s fun and safe nightlife.",
            "Simela Namavar, jazz singer",
          ],
        },
      ],
      media: [
        {
          type: "video",
          orientation: "landscape",
          credit: "Best In Travel 2025",
          src: {
            poster:
              "https://lonelyplanetstatic.imgix.net/marketing/best-in-travel/2025/videos/armenia/armenia-trailer-landscape.jpg",
            mp4: "https://lonelyplanetstatic.imgix.net/marketing/best-in-travel/2025/videos/armenia/armenia-trailer-landscape.mp4",
            webm: "https://lonelyplanetstatic.imgix.net/marketing/best-in-travel/2025/videos/armenia/armenia-trailer-landscape.webm",
            jwvid: "dA2097hn",
          },
        },
        {
          type: "video",
          orientation: "portrait",
          credit: "Best In Travel 2025",
          src: {
            poster:
              "https://lonelyplanetstatic.imgix.net/marketing/best-in-travel/2025/videos/armenia/armenia-trailer-portrait.jpg",
            mp4: "https://lonelyplanetstatic.imgix.net/marketing/best-in-travel/2025/videos/armenia/armenia-trailer-portrait.mp4",
            webm: "https://lonelyplanetstatic.imgix.net/marketing/best-in-travel/2025/videos/armenia/armenia-trailer-portrait.webm",
            jwvid: "TwGiePeE",
          },
        },
        {
          type: "image",
          orientation: "portrait",
          src: "https://lonelyplanetstatic.imgix.net/marketing/best-in-travel/2025/categories/countries/armenia/man-walking-stone-formation-portrait.jpg",
          alt: "A man walking below large stone formations on the side of a mountain",
          credit: "Tatiana Nadyseva/Tiny Atlas x Kintzing",
        },
        {
          type: "image",
          src: "https://lonelyplanetstatic.imgix.net/marketing/best-in-travel/2025/categories/countries/armenia/man-walking-stone-formation.jpg",
          alt: "A man walking below large stone formations on the side of a mountain",
          credit: "Tatiana Nadyseva/Tiny Atlas x Kintzing",
        },
        {
          type: "image",
          orientation: "portrait",
          src: "https://lonelyplanetstatic.imgix.net/marketing/best-in-travel/2025/categories/countries/armenia/car-mountains-yellow-portrait.jpg",
          alt: "A view of a car hood with yellow landscape in the background",
          credit: "Tatiana Nadyseva/Tiny Atlas x Kintzing",
        },
        {
          type: "image",
          src: "https://lonelyplanetstatic.imgix.net/marketing/best-in-travel/2025/categories/countries/armenia/car-mountains-yellow.jpg",
          alt: "A view of a car hood with yellow landscape in the background",
          credit: "Tatiana Nadyseva/Tiny Atlas x Kintzing",
        },
        {
          type: "image",
          orientation: "portrait",
          src: "https://lonelyplanetstatic.imgix.net/marketing/best-in-travel/2025/categories/countries/armenia/trinkets-silver-red-tablecloth-portrait.jpg",
          alt: "Silver trinkets laid out on a red tablecloth at a market",
          credit: "AVA Films for ������ϲʼ�ʱ����",
        },
        {
          type: "image",
          src: "https://lonelyplanetstatic.imgix.net/marketing/best-in-travel/2025/categories/countries/armenia/trinkets-silver-red-tablecloth.jpg",
          alt: "Silver trinkets laid out on a red tablecloth at a market",
          credit: "AVA Films for ������ϲʼ�ʱ����",
        },
        {
          type: "image",
          orientation: "portrait",
          src: "https://lonelyplanetstatic.imgix.net/marketing/best-in-travel/2025/categories/countries/armenia/hillside-horses-mountains-portrait.jpg",
          alt: "A hillside with horses and buildings in the distance",
          credit: "Tatiana Nadyseva/Tiny Atlas x Kintzing",
        },
        {
          type: "image",
          src: "https://lonelyplanetstatic.imgix.net/marketing/best-in-travel/2025/categories/countries/armenia/hillside-horses-mountains.jpg",
          alt: "A hillside with horses and buildings in the distance",
          credit: "Tatiana Nadyseva/Tiny Atlas x Kintzing",
        },
      ],
      articles: ["/articles/first-time-guide-to-armenia"],
    },

    // REGIONS
    {
      name: "Lowcountry, South Carolina and Georgia",
      slug: "/usa/the-south/south-carolina",
      saily: "https://go.saily.site/aff_c?offer_id=101&aff_id=5957&url_id=1356",
      meta: {
        category: "regions",
        parent: "USA",
        tags: [],
        coords: {
          lat: 32.36778594,
          lon: -80.96988196,
        },
      },
      editorial: [
        {
          type: "p",
          children:
            "The sultry vibe of Lowcountry in South Carolina and Georgia has long lured visitors to its miles of beaches and marshes, as well as its big-name cities, Charleston and Savannah. This region has one of the country’s most vibrant food scenes, and its complicated history has thoughtfully come to the fore at the recently opened International African American Museum.",
        },
        {
          type: "ol",
          children: [
            "Spend the afternoon strolling Savannah’s Starland District, taking in street murals, vintage shops and bohemian cafes.",
            "Go gallery hopping in Charleston’s cobblestoned French Quarter, then return by night for a performance at the historic Dock Street Theatre.",
            "Learn about the lives of enslaved people touring a Gullah cemetery and former dwelling on the 19th-century McLeod Plantation.",
          ],
        },
        {
          type: "blockquote",
          children: [
            "I moved to Savannah to live in a historic city with great parks for walking, running and enjoying the outdoors. I also love being close to the beach – lovely Tybee Island is less than 30 minutes away.",
            "Rachel Seeley, athletic trainer",
          ],
        },
      ],
      media: [
        {
          type: "video",
          orientation: "landscape",
          credit: "Best In Travel 2025",
          src: {
            poster:
              "https://lonelyplanetstatic.imgix.net/marketing/best-in-travel/2025/videos/south-carolina/south-carolina-trailer-landscape.jpg",
            mp4: "https://lonelyplanetstatic.imgix.net/marketing/best-in-travel/2025/videos/south-carolina/south-carolina-trailer-landscape.mp4",
            webm: "https://lonelyplanetstatic.imgix.net/marketing/best-in-travel/2025/videos/south-carolina/south-carolina-trailer-landscape.webm",
            jwvid: "HY4Nd1C4",
          },
        },
        {
          type: "video",
          orientation: "portrait",
          credit: "Best In Travel 2025",
          src: {
            poster:
              "https://lonelyplanetstatic.imgix.net/marketing/best-in-travel/2025/videos/south-carolina/south-carolina-trailer-portrait.jpg",
            mp4: "https://lonelyplanetstatic.imgix.net/marketing/best-in-travel/2025/videos/south-carolina/south-carolina-trailer-portrait.mp4",
            webm: "https://lonelyplanetstatic.imgix.net/marketing/best-in-travel/2025/videos/south-carolina/south-carolina-trailer-portrait.webm",
            jwvid: "P8yHmT4O",
          },
        },
        {
          type: "image",
          orientation: "portrait",
          src: "https://lonelyplanetstatic.imgix.net/marketing/best-in-travel/2025/categories/regions/south-carolina/woman-blue-dress-smiling-tree-portrait.jpg",
          alt: "A woman in blue smiling underneath a large tree with grass around her",
          credit: "Ethan Payne for ������ϲʼ�ʱ����",
        },
        {
          type: "image",
          src: "https://lonelyplanetstatic.imgix.net/marketing/best-in-travel/2025/categories/regions/south-carolina/woman-blue-dress-smiling-tree.jpg",
          alt: "A woman in blue smiling underneath a large tree with grass around her",
          credit: "Ethan Payne for ������ϲʼ�ʱ����",
        },
        {
          type: "image",
          orientation: "portrait",
          src: "https://lonelyplanetstatic.imgix.net/marketing/best-in-travel/2025/categories/regions/south-carolina/oysters-lemon-plate-ice-wood-portrait.jpg",
          alt: "Oysters on a plate with a wood table underneath and a slice of lemon",
          credit: "Ethan Payne for ������ϲʼ�ʱ����",
        },
        {
          type: "image",
          src: "https://lonelyplanetstatic.imgix.net/marketing/best-in-travel/2025/categories/regions/south-carolina/oysters-lemon-plate-ice-wood.jpg",
          alt: "Oysters on a plate with a wood table underneath and a slice of lemon",
          credit: "Ethan Payne for ������ϲʼ�ʱ����",
        },
        {
          type: "image",
          orientation: "portrait",
          src: "https://lonelyplanetstatic.imgix.net/marketing/best-in-travel/2025/categories/regions/south-carolina/trees-spanish-moss-road-portrait.jpg",
          alt: "A road with trees leaning towards each other and green",
          credit: "Ethan Payne for ������ϲʼ�ʱ����",
        },
        {
          type: "image",
          src: "https://lonelyplanetstatic.imgix.net/marketing/best-in-travel/2025/categories/regions/south-carolina/trees-spanish-moss-road.jpg",
          alt: "A road with trees leaning towards each other and green",
          credit: "Ethan Payne for ������ϲʼ�ʱ����",
        },
        {
          type: "image",
          orientation: "portrait",
          src: "https://lonelyplanetstatic.imgix.net/marketing/best-in-travel/2025/categories/regions/south-carolina/aerial-church-river-sunset-portrait.jpg",
          alt: "An aerial view of a river with a town and a sunset",
          credit: "Ethan Payne for ������ϲʼ�ʱ����",
        },
        {
          type: "image",
          src: "https://lonelyplanetstatic.imgix.net/marketing/best-in-travel/2025/categories/regions/south-carolina/aerial-church-river-sunset.jpg",
          alt: "An aerial view of a river with a town and a sunset",
          credit: "Ethan Payne for ������ϲʼ�ʱ����",
        },
      ],
      articles: [
        "/articles/charleston-vs-savannah",
        "/articles/best-independent-shops-savannah-georgia",
        "/articles/ultimate-weekend-in-savannah-georgia",
        "/articles/where-to-eat-drink-charleston",
      ],
    },
    {
      name: "The Terai",
      slug: "/nepal/the-terai-and-mahabharat-range",
      saily: false,
      meta: {
        category: "regions",
        parent: "Nepal",
        tags: [],
        coords: {
          lat: 27.38467797,
          lon: 83.92505074,
        },
      },
      editorial: [
        {
          type: "p",
          children:
            "Nepal's Terai region may not be as well-known as the Himalayas or Kathmandu, but these southern lowlands offer as many extraordinary experiences. See one-horned rhinos, saved from extinction, at Chitwan National Park or visit the Maya Devi temple in Lumbini, where the Buddha was born around 563 BCE.",
        },
        {
          type: "ol",
          children: [
            "Take a canoe or boat safari along Chitwan’s Rapti River, keeping your eyes peeled for rhinos and huge gharial crocodiles.",
            "Look round Lumbini’s Maya Devi temple, which marks the birthplace of the Buddha.",
            "Enjoy a tasting session and help out with the harvest on a tour of one of Ilam’s tea gardens.",
          ],
        },
        {
          type: "blockquote",
          children: [
            "Chitwan’s popularity has always dwarfed Bardiya’s, but this ‘Wild Wild West’ on the southwestern plains of the Terai is the place to be for travelers and wildlife enthusiasts seeking experiences of the other side of Nepal.",
            "Abhi Shrestha, CEO of Rural Heritage/Snow Cat Travel",
          ],
        },
      ],
      media: [
        {
          type: "image",
          orientation: "portrait",
          src: "https://lonelyplanetstatic.imgix.net/marketing/best-in-travel/2025/categories/regions/the-terai/monk-tree-temple-sitting-portrait.jpg",
          alt: "A monk sitting on a platform next to a tree and looking at a temple",
          credit: "Monika Deupala for ������ϲʼ�ʱ����",
        },
        {
          type: "image",
          src: "https://lonelyplanetstatic.imgix.net/marketing/best-in-travel/2025/categories/regions/the-terai/monk-tree-temple-sitting.jpg",
          alt: "A monk sitting on a platform next to a tree and looking at a temple",
          credit: "Monika Deupala for ������ϲʼ�ʱ����",
        },
        {
          type: "image",
          orientation: "portrait",
          src: "https://lonelyplanetstatic.imgix.net/marketing/best-in-travel/2025/categories/regions/the-terai/girl-waitress-smiling-food-portrait.jpg",
          alt: "A young girl smiling and carrying a tray of food in a restaurant",
          credit: "Monika Deupala for ������ϲʼ�ʱ����",
        },
        {
          type: "image",
          src: "https://lonelyplanetstatic.imgix.net/marketing/best-in-travel/2025/categories/regions/the-terai/girl-waitress-smiling-food.jpg",
          alt: "A young girl smiling and carrying a tray of food in a restaurant",
          credit: "Monika Deupala for ������ϲʼ�ʱ����",
        },
        {
          _comment: "#3 mobile",
          type: "image",
          orientation: "portrait",
          src: "https://lonelyplanetstatic.imgix.net/marketing/best-in-travel/2025/categories/regions/the-terai/elephants-river-water-portrait.jpg",
          alt: "Two elephants spraying water in a river with grass behind them",
          credit: "Monika Deupala for ������ϲʼ�ʱ����",
        },
        {
          _comment: "#3 desktop",
          type: "image",
          src: "https://lonelyplanetstatic.imgix.net/marketing/best-in-travel/2025/categories/regions/the-terai/rhino-river-water.jpg",
          alt: "A rhino half submerged in water in a river with greenery",
          credit: "Monika Deupala for ������ϲʼ�ʱ����",
        },
        {
          type: "image",
          orientation: "portrait",
          src: "https://lonelyplanetstatic.imgix.net/marketing/best-in-travel/2025/categories/regions/the-terai/temple-statues-ornate-decorations-portrait.jpg",
          alt: "Two statues with ornate decorations in front of a window",
          credit: "Monika Deupala for ������ϲʼ�ʱ����",
        },
        {
          type: "image",
          src: "https://lonelyplanetstatic.imgix.net/marketing/best-in-travel/2025/categories/regions/the-terai/temple-statues-ornate-decorations.jpg",
          alt: "Two statues with ornate decorations in front of a window",
          credit: "Monika Deupala for ������ϲʼ�ʱ����",
        },
        {
          type: "image",
          orientation: "portrait",
          src: "https://lonelyplanetstatic.imgix.net/marketing/best-in-travel/2025/categories/regions/the-terai/town-building-people-windows-portrait.jpg",
          alt: "A white building with artwork painted on it and people sitting outside",
          credit: "Monika Deupala for ������ϲʼ�ʱ����",
        },
        {
          type: "image",
          src: "https://lonelyplanetstatic.imgix.net/marketing/best-in-travel/2025/categories/regions/the-terai/town-building-people-windows.jpg",
          alt: "A white building with artwork painted on it and people sitting outside",
          credit: "Monika Deupala for ������ϲʼ�ʱ����",
        },
      ],
      articles: [
        "/articles/first-time-guide-to-the-terai",
        "/articles/best-time-to-visit-nepal",
        "/articles/best-places-to-visit-in-nepal",
      ],
    },
    {
      name: "Chiriquí",
      slug: "/panama/chiriqui-province",
      saily: "https://go.saily.site/aff_c?offer_id=101&aff_id=5957&url_id=1344",
      meta: {
        category: "regions",
        parent: "Panama",
        tags: [],
        coords: {
          lat: 8.62911292,
          lon: -82.78318368,
        },
      },
      editorial: [
        {
          type: "p",
          children:
            "Situated at the westernmost edge of Panama, the province of Chiriquí delivers astounding biodiversity as well as options for coffee tours, boat trips and snorkeling in pristine surroundings. Volcán Barú National Park presents an exhilarating challenge for hikers. The payoff for reaching the top? A view of both the Atlantic and Pacific Oceans.",
        },
        {
          type: "ol",
          children: [
            "Make the charming, riverside town of Boquete your starting point for activities in the province of Chiriquí.",
            "Be wowed by Finca Drácula if you’re a flower lover, a botanical garden holding more than 2000 types of orchids, one of the largest collections in the world.",
            "Get wet and wild rafting the white waters of Chiriquí Viejo River.",
          ],
        },
        {
          type: "blockquote",
          children: [
            "In Panama, nature is pristine. It creates a favorable environment because we are all happy to be here. The country boasts a remarkably healthy ecosystem, and our collective responsibility is to preserve and care for it.",
            "Jeffrey Dietrich, owner of Mount Totumas Cloud Forest and Biological Reserve",
          ],
        },
      ],
      media: [
        {
          type: "video",
          orientation: "landscape",
          credit: "Best In Travel 2025",
          src: {
            poster:
              "https://lonelyplanetstatic.imgix.net/marketing/best-in-travel/2025/videos/chiriqui/chiriqui-trailer-landscape.jpg",
            mp4: "https://lonelyplanetstatic.imgix.net/marketing/best-in-travel/2025/videos/chiriqui/chiriqui-trailer-landscape.mp4",
            webm: "https://lonelyplanetstatic.imgix.net/marketing/best-in-travel/2025/videos/chiriqui/chiriqui-trailer-landscape.webm",
            jwvid: "Kr2fFN5O",
          },
        },
        {
          type: "video",
          orientation: "portrait",
          credit: "Best In Travel 2025",
          src: {
            poster:
              "https://lonelyplanetstatic.imgix.net/marketing/best-in-travel/2025/videos/chiriqui/chiriqui-trailer-portrait.jpg",
            mp4: "https://lonelyplanetstatic.imgix.net/marketing/best-in-travel/2025/videos/chiriqui/chiriqui-trailer-portrait.mp4",
            webm: "https://lonelyplanetstatic.imgix.net/marketing/best-in-travel/2025/videos/chiriqui/chiriqui-trailer-portrait.webm",
            jwvid: "ucdi0XFo",
          },
        },
        {
          type: "image",
          orientation: "portrait",
          src: "https://lonelyplanetstatic.imgix.net/marketing/best-in-travel/2025/categories/regions/chiriqui/woman-beach-palm-trees-sand-portrait.jpg",
          alt: "A woman walks to the right of the frame down a sandy beach with palm trees behind her",
          credit: "AWL Images",
        },
        {
          type: "image",
          src: "https://lonelyplanetstatic.imgix.net/marketing/best-in-travel/2025/categories/regions/chiriqui/woman-beach-palm-trees-sand.jpg",
          alt: "A woman walks to the right of the frame down a sandy beach with palm trees behind her",
          credit: "AWL Images",
        },
        {
          type: "image",
          orientation: "portrait",
          src: "https://lonelyplanetstatic.imgix.net/marketing/best-in-travel/2025/categories/regions/chiriqui/woman-orchid-flower-man-sniff-portrait.jpg",
          alt: "A woman leans in to smell a yellow orchid with a man doing the same over her shoulder",
          credit: "GP Productions",
        },
        {
          type: "image",
          src: "https://lonelyplanetstatic.imgix.net/marketing/best-in-travel/2025/categories/regions/chiriqui/woman-orchid-flower-man-sniff.jpg",
          alt: "A woman leans in to smell a yellow orchid with a man doing the same over her shoulder",
          credit: "GP Productions",
        },
        {
          type: "image",
          orientation: "portrait",
          src: "https://lonelyplanetstatic.imgix.net/marketing/best-in-travel/2025/categories/regions/chiriqui/people-rock-climbing-rocks-portrait.jpg",
          alt: "A woman holds a rope in a harness for someone rock climbing up a small cliff",
          credit: "GP Productions",
        },
        {
          type: "image",
          src: "https://lonelyplanetstatic.imgix.net/marketing/best-in-travel/2025/categories/regions/chiriqui/people-rock-climbing-rocks.jpg",
          alt: "A woman holds a rope in a harness for someone rock climbing up a small cliff",
          credit: "GP Productions",
        },
        {
          type: "image",
          orientation: "portrait",
          src: "https://lonelyplanetstatic.imgix.net/marketing/best-in-travel/2025/categories/regions/chiriqui/church-statue-blue-sky-clouds-portrait.jpg",
          alt: "A church in the background and a statue of two people and a bird in the foreground",
          credit: "GP Productions",
        },
        {
          type: "image",
          src: "https://lonelyplanetstatic.imgix.net/marketing/best-in-travel/2025/categories/regions/chiriqui/church-statue-blue-sky-clouds.jpg",
          alt: "A church in the background and a statue of two people and a bird in the foreground",
          credit: "GP Productions",
        },
      ],
      articles: [
        "/articles/guide-to-chiriqui-panama",
        "/articles/best-time-to-visit-panama",
        "/articles/best-places-to-visit-in-panama",
      ],
    },
    {
      name: "Launceston and the Tamar Valley",
      slug: "/australia/tasmania/launceston",
      saily: "https://go.saily.site/aff_c?offer_id=101&aff_id=5957&url_id=1344",
      meta: {
        category: "regions",
        parent: "Australia",
        tags: [],
        coords: {
          lat: -41.84632552,
          lon: 147.1388263,
        },
      },
      editorial: [
        {
          type: "p",
          children:
            "Drive north or south of Launceston in Tasmania, and you’ll hit a Tamar Valley cellar door within 12km (7 miles) of the city center. The state’s oldest and largest wine region spills across both banks of kanamaluka/River Tamar and contains more than 30 vineyards that can be strung together on the Tamar Valley Wine Route.",
        },
        {
          type: "ol",
          children: [
            "Thread through Cataract Gorge on walking trails, peer down onto peacocks and wallabies from the chairlift, or dine in style at Gorge Restaurant.",
            "Head downstream to Beauty Point, viewing platypuses and wandering among feeding echidnas at Platypus House.",
            "Plot your Tasmanian bushwalks on the maps that cover the cavernous walls of Du Cane, Launceston’s first craft brewery.",
          ],
        },
        {
          type: "blockquote",
          children: [
            "What I love about Lonnie is that it’s a city that’s really just a big country town, with heaps of hidden gems. Favorite watering holes are Havilah wine bar and Saint John Craft Beer.",
            "Rhys Hannan, market manager, Harvest Launceston",
          ],
        },
      ],
      media: [
        {
          _comment: "#1 desktop",
          type: "image",
          src: "https://lonelyplanetstatic.imgix.net/marketing/best-in-travel/2025/categories/regions/launceston-and-the-tamar-valley/ocean-view-sky-brush.jpg",
          alt: "An overhead view of a wavy ocean with brush on either side and mountains in the distance",
          credit: "Ness Vanderburgh for ������ϲʼ�ʱ����",
        },
        {
          _comment: "#1 mobile",
          type: "image",
          orientation: "portrait",
          src: "https://lonelyplanetstatic.imgix.net/marketing/best-in-travel/2025/categories/regions/launceston-and-the-tamar-valley/man-market-aprin-cheese-knife-portrait.jpg",
          alt: "A man in an apron cuts cheese with a knife in a market setting",
          credit: "Ness Vanderburgh for ������ϲʼ�ʱ����",
        },
        {
          _comment: "#2 desktop",
          type: "image",
          src: "https://lonelyplanetstatic.imgix.net/marketing/best-in-travel/2025/categories/regions/launceston-and-the-tamar-valley/man-market-aprin-cheese-knife.jpg",
          alt: "A man in an apron cuts cheese with a knife in a market setting",
          credit: "Ness Vanderburgh for ������ϲʼ�ʱ����",
        },
        {
          _comment: "#2 mobile",
          type: "image",
          orientation: "portrait",
          src: "https://lonelyplanetstatic.imgix.net/marketing/best-in-travel/2025/categories/regions/launceston-and-the-tamar-valley/ocean-view-sky-brush-portrait.jpg",
          alt: "An overhead view of a wavy ocean with brush on either side and mountains in the distance",
          credit: "Ness Vanderburgh for ������ϲʼ�ʱ����",
        },
        {
          type: "image",
          orientation: "portrait",
          src: "https://lonelyplanetstatic.imgix.net/marketing/best-in-travel/2025/categories/regions/launceston-and-the-tamar-valley/steak-chickpeas-food-plates-portrait.jpg",
          alt: "A meal of steak and chickpeas with sauce on a white plate",
          credit: "Ness Vanderburgh for ������ϲʼ�ʱ����",
        },
        {
          type: "image",
          src: "https://lonelyplanetstatic.imgix.net/marketing/best-in-travel/2025/categories/regions/launceston-and-the-tamar-valley/steak-chickpeas-food-plates.jpg",
          alt: "A meal of steak and chickpeas with sauce on a white plate",
          credit: "Ness Vanderburgh for ������ϲʼ�ʱ����",
        },
        {
          _comment: "#4 desktop",
          type: "image",
          src: "https://lonelyplanetstatic.imgix.net/marketing/best-in-travel/2025/categories/regions/launceston-and-the-tamar-valley/plates-bookshelf-antiques.jpg",
          alt: "A scene in an antique shop of a bookshelf full of plates, knick knacks",
          credit: "Ness Vanderburgh for ������ϲʼ�ʱ����",
        },
        {
          _comment: "#4 mobile",
          type: "image",
          orientation: "portrait",
          src: "https://lonelyplanetstatic.imgix.net/marketing/best-in-travel/2025/categories/regions/launceston-and-the-tamar-valley/women-table-wine-restaurant-window-portrait.jpg",
          alt: "Two women eating with two glasses of wine in front of windows",
          credit: "Ness Vanderburgh for ������ϲʼ�ʱ����",
        },
        {
          _comment: "#5 desktop",
          type: "image",
          src: "https://lonelyplanetstatic.imgix.net/marketing/best-in-travel/2025/categories/regions/launceston-and-the-tamar-valley/vineyard-house-water-trees.jpg",
          alt: "A scene of a vineyard with a house on the left, water in the background, and vines in the foreground",
          credit: "Ness Vanderburgh for ������ϲʼ�ʱ����",
        },
        {
          _comment: "#5 mobile",
          type: "image",
          orientation: "portrait",
          src: "https://lonelyplanetstatic.imgix.net/marketing/best-in-travel/2025/categories/regions/launceston-and-the-tamar-valley/bird-blue-green-background.jpg",
          alt: "A blue bird with plumage with green trees behind it",
          credit: "Ness Vanderburgh for ������ϲʼ�ʱ����",
        },
      ],
      articles: [
        "/articles/first-time-guide-to-launceston",
        "/articles/best-time-to-visit-australia",
        "/articles/best-places-to-visit-in-australia",
      ],
    },
    {
      name: "Valais",
      slug: "/switzerland/valais",
      saily: "https://go.saily.site/aff_c?offer_id=101&aff_id=5957&url_id=1353",
      meta: {
        category: "regions",
        parent: "Switzerland",
        tags: [],
        coords: {
          lat: 46.22043044,
          lon: 7.62220263,
        },
      },
      editorial: [
        {
          type: "p",
          children:
            "Verbier, Zermatt and the Matterhorn need no introduction – they are the icons of Switzerland's Valais region. In summer 2025 the region will host the UCI World Mountain Bike Championships, with the opening ceremony firing up the quiet town of Sion, 55km (34 miles) from Verbier, followed by biking events at seven surrounding mountain resorts.",
        },
        {
          type: "ol",
          children: [
            "Ride three cable cars from Zermatt to Klein Matterhorn to spot 14 glaciers and 30-plus peaks above 4000m (13,123ft).",
            "Experience Verbier from a new angle: summit Pierre Avoi and hike along centuries-old bisses (waterways) built to irrigate pastures.",
            "Explore the Aletsch Glacier, the Alps’ largest sea of ice, on a guided summer glacier hike or winter ski-touring expedition from Fiesch.",
          ],
        },
        {
          type: "blockquote",
          children: [
            "Yes, we do eat raclette in summer too! The best spot in Sion is the Friday market or Philippe Savioz’ Cave Les Futailles, a wine cellar from the 13th century on Rue du Grand Pont.",
            "Sabine de Kalbermattenvan Vliet, Valais native",
          ],
        },
      ],
      media: [
        {
          type: "video",
          orientation: "landscape",
          credit: "Best In Travel 2025",
          src: {
            poster:
              "https://lonelyplanetstatic.imgix.net/marketing/best-in-travel/2025/videos/valais/valais-trailer-landscape.jpg",
            mp4: "https://lonelyplanetstatic.imgix.net/marketing/best-in-travel/2025/videos/valais/valais-trailer-landscape.mp4",
            webm: "https://lonelyplanetstatic.imgix.net/marketing/best-in-travel/2025/videos/valais/valais-trailer-landscape.webm",
            jwvid: "iB583kuQ",
          },
        },
        {
          type: "video",
          orientation: "portrait",
          credit: "Best In Travel 2025",
          src: {
            poster:
              "https://lonelyplanetstatic.imgix.net/marketing/best-in-travel/2025/videos/valais/valais-trailer-portrait.jpg",
            mp4: "https://lonelyplanetstatic.imgix.net/marketing/best-in-travel/2025/videos/valais/valais-trailer-portrait.mp4",
            webm: "https://lonelyplanetstatic.imgix.net/marketing/best-in-travel/2025/videos/valais/valais-trailer-portrait.webm",
            jwvid: "ZCVPJn0q",
          },
        },
        {
          type: "image",
          orientation: "portrait",
          src: "https://lonelyplanetstatic.imgix.net/marketing/best-in-travel/2025/categories/regions/valais/small-town-flowerbeds-mountain-portrait.jpg",
          alt: "A mountain with clouds in the distance and small town buildings in the foreground",
          credit: "Ian Kalbermatten for ������ϲʼ�ʱ����",
        },
        {
          type: "image",
          src: "https://lonelyplanetstatic.imgix.net/marketing/best-in-travel/2025/categories/regions/valais/small-town-flowerbeds-mountain.jpg",
          alt: "A mountain with clouds in the distance and small town buildings in the foreground",
          credit: "Ian Kalbermatten for ������ϲʼ�ʱ����",
        },
        {
          type: "image",
          orientation: "portrait",
          src: "https://lonelyplanetstatic.imgix.net/marketing/best-in-travel/2025/categories/regions/valais/sight-seeing-with-orange-viewfinder-mountains-portrait.jpg",
          alt: "A sightseeing orange binoculars with mountains behind them on a trail with snow",
          credit: "Ian Kalbermatten for ������ϲʼ�ʱ����",
        },
        {
          type: "image",
          src: "https://lonelyplanetstatic.imgix.net/marketing/best-in-travel/2025/categories/regions/valais/sight-seeing-with-orange-viewfinder-mountains.jpg",
          alt: "A sightseeing orange binoculars with mountains behind them on a trail with snow",
          credit: "Ian Kalbermatten for ������ϲʼ�ʱ����",
        },
        {
          type: "image",
          orientation: "portrait",
          src: "https://lonelyplanetstatic.imgix.net/marketing/best-in-travel/2025/categories/regions/valais/mountain-with-sunrise-behind-portrait.jpg",
          alt: "Mountain in the distance with sunrise with leaves in the foreground",
          credit: "Ian Kalbermatten for ������ϲʼ�ʱ����",
        },
        {
          type: "image",
          src: "https://lonelyplanetstatic.imgix.net/marketing/best-in-travel/2025/categories/regions/valais/mountain-with-sunrise-behind.jpg",
          alt: "Mountain in the distance with sunrise with leaves in the foreground",
          credit: "Ian Kalbermatten for ������ϲʼ�ʱ����",
        },
      ],
      articles: [
        "/articles/best-things-to-do-in-valais",
        "/articles/skiing-in-valais",
      ],
    },
    {
      name: "Giresun and Ordu",
      slug: "/articles/first-time-giresun-ordu-turkey",
      saily: "https://go.saily.site/aff_c?offer_id=101&aff_id=5957&url_id=1344",
      meta: {
        category: "regions",
        parent: "Türkiye",
        tags: [],
        coords: {
          lat: 40.85497903,
          lon: 37.96214201,
        },
      },
      editorial: [
        {
          type: "p",
          children:
            "Türkiye's Black Sea region often goes unexplored by travelers. Its small, charming coastal provinces, Giresun and Ordu, are known for their welcoming hospitality. Explore the coastal and mountainous areas of the region. This is a walker's paradise with plenty of trekking routes offering opportunities to discover the region’s natural beauty.",
        },
        {
          type: "ol",
          children: [
            "Taste the world’s most delicious fındık (hazelnuts) and hazelnut paste in Giresun.",
            "Try the famous, mouthwatering Görele pide (Turkish-style pizza).",
            "Visit the Mavi Göl (Blue Lake) in the middle of the verdant countryside that turns turquoise, especially in the summer months.",
          ],
        },
        {
          type: "blockquote",
          children: [
            "As you travel along the coasts of Ordu and Giresun, you should fill your lungs with the scent of the Black Sea, stop by unspoilt bays, drink a cup of çay (tea) in a fishing village and perhaps set sail on a boat.",
            "Emine Fatoğlu Topkaya, local tour guide",
          ],
        },
      ],
      media: [
        {
          type: "image",
          src: "https://lonelyplanetstatic.imgix.net/marketing/best-in-travel/2025/categories/regions/giresun-and-ordu/mountain-structure-hidden-building.jpg",
          alt: "A large building built into a hole in the side of a mountain with a road leading up to it",
          credit: "Getty Images",
        },
        {
          type: "image",
          src: "https://lonelyplanetstatic.imgix.net/marketing/best-in-travel/2025/categories/regions/giresun-and-ordu/man-hand-pulling-cheese-picnic.jpg",
          alt: "A man's hand pulling cheese out of a copper pot with a traditional breakfast on a picnic table",
          credit: "Getty Images",
        },
        {
          type: "image",
          src: "https://lonelyplanetstatic.imgix.net/marketing/best-in-travel/2025/categories/regions/giresun-and-ordu/mountain-reflected-lake-clouds.jpg",
          alt: "A hillside with green and snow reflected in a lake with clouds above",
          credit: "Kenan Talas/Getty Images",
        },
        {
          type: "image",
          src: "https://lonelyplanetstatic.imgix.net/marketing/best-in-travel/2025/categories/regions/giresun-and-ordu/three-boys-beach-sand.jpg",
          alt: "Three boys on a beach with sand and waves facing away from the camera",
          credit: "Getty Images",
        },
        {
          type: "image",
          src: "https://lonelyplanetstatic.imgix.net/marketing/best-in-travel/2025/categories/regions/giresun-and-ordu/hands-floral-skirt-acorn.jpg",
          alt: "A woman's hands open up fresh acorns white shirt floral skirt",
          credit: "Getty Images",
        },
        {
          type: "image",
          orientation: "portrait",
          src: "https://lonelyplanetstatic.imgix.net/marketing/best-in-travel/2025/categories/regions/giresun-and-ordu/mountain-structure-hidden-building-portrait.jpg",
          alt: "A large building built into a hole in the side of a mountain with a road leading up to it",
          credit: "Getty Images",
        },
        {
          type: "image",
          orientation: "portrait",
          src: "https://lonelyplanetstatic.imgix.net/marketing/best-in-travel/2025/categories/regions/giresun-and-ordu/man-hand-pulling-cheese-picnic-portrait.jpg",
          alt: "A man's hand pulling cheese out of a copper pot with a traditional breakfast on a picnic table",
          credit: "Getty Images",
        },
        {
          type: "image",
          orientation: "portrait",
          src: "https://lonelyplanetstatic.imgix.net/marketing/best-in-travel/2025/categories/regions/giresun-and-ordu/mountain-reflected-lake-clouds-portrait.jpg",
          alt: "A hillside with green and snow reflected in a lake with clouds above",
          credit: "Kenan Talas/Getty Images",
        },
        {
          type: "image",
          orientation: "portrait",
          src: "https://lonelyplanetstatic.imgix.net/marketing/best-in-travel/2025/categories/regions/giresun-and-ordu/three-boys-beach-sand-portrait.jpg",
          alt: "Three boys on a beach with sand and waves facing away from the camera",
          credit: "Getty Images",
        },
        {
          type: "image",
          orientation: "portrait",
          src: "https://lonelyplanetstatic.imgix.net/marketing/best-in-travel/2025/categories/regions/giresun-and-ordu/hands-floral-skirt-acorn-portrait.jpg",
          alt: "A woman's hands open up fresh acorns white shirt floral skirt",
          credit: "Getty Images",
        },
      ],
      articles: [
        "/articles/first-time-giresun-ordu-turkey",
        "/articles/best-time-to-visit-turkey",
        "/articles/best-places-to-visit-in-turkey",
      ],
    },
    {
      name: "Bavaria",
      slug: "/germany/bavaria",
      saily: "https://go.saily.site/aff_c?offer_id=101&aff_id=5957&url_id=1344",
      meta: {
        category: "regions",
        parent: "Germany",
        tags: [],
        coords: {
          lat: 48.95830613,
          lon: 11.77435421,
        },
      },
      editorial: [
        {
          type: "p",
          children:
            "Exploring the outdoors is a must when visiting Bavaria, particularly in the Alps bordering Austria. Garmisch-Partenkirchen, a top Alpine resort in Germany, offers a wide range of activities throughout the year, making it particularly appealing for those who don't ski. Don’t miss Munich's Oktoberfest (beginning in September) – the largest beer festival on the planet.",
        },
        {
          type: "ol",
          children: [
            "Spend an evening in a beer garden such as Munich’s Chinesischer Turm or at the Forchheimer Kellerwald.",
            "Head south to the madly popular Neuschwanstein Castle, Ludwig II’s most iconic fairy-tale pile set among the Alps.",
            "Road-trip down Bavaria’s west on Germany’s most popular holiday route, the extremely quaint Romantic Road.",
          ],
        },
        {
          type: "blockquote",
          children: [
            "The best way to start your day in Munich is with a Weisswurst breakfast – a pair of veal sausages, a crispy pretzel, sweet mustard and a tankard of wheat beer.",
            "José Pavez, Munich tour guide",
          ],
        },
      ],
      media: [
        {
          type: "image",
          orientation: "portrait",
          src: "https://lonelyplanetstatic.imgix.net/marketing/best-in-travel/2025/categories/regions/bavaria/women-bathing-suits-german-beach-chair-portrait.jpg",
          alt: "One woman in a bathing suit in the water and another on a dock with a lawn chair and mountains behind them",
          credit: "Peter von Felbert/Erlebe Bayern",
        },
        {
          type: "image",
          src: "https://lonelyplanetstatic.imgix.net/marketing/best-in-travel/2025/categories/regions/bavaria/women-bathing-suits-german-beach-chair.jpg",
          alt: "One woman in a bathing suit in the water and another on a dock with a lawn chair and mountains behind them",
          credit: "Peter von Felbert/Erlebe Bayern",
        },
        {
          type: "image",
          orientation: "portrait",
          src: "https://lonelyplanetstatic.imgix.net/marketing/best-in-travel/2025/categories/regions/bavaria/two-hikers-path-green-mountain-portrait.jpg",
          alt: "Two hikers walking down a sandy path on the top of a mountain aerial view",
          credit: "Frank Heuer/Erlebe Bayern",
        },
        {
          type: "image",
          src: "https://lonelyplanetstatic.imgix.net/marketing/best-in-travel/2025/categories/regions/bavaria/two-hikers-path-green-mountain.jpg",
          alt: "Two hikers walking down a sandy path on the top of a mountain aerial view",
          credit: "Frank Heuer/Erlebe Bayern",
        },
        {
          type: "image",
          orientation: "portrait",
          src: "https://lonelyplanetstatic.imgix.net/marketing/best-in-travel/2025/categories/regions/bavaria/flowers-house-river-roof-portrait.jpg",
          alt: "A view of flowers trees and a river flanked by houses on either side",
          credit: "Thomas Linkel/Erlebe Bayern",
        },
        {
          type: "image",
          src: "https://lonelyplanetstatic.imgix.net/marketing/best-in-travel/2025/categories/regions/bavaria/flowers-house-river-roof.jpg",
          alt: "A view of flowers trees and a river flanked by houses on either side",
          credit: "Thomas Linkel/Erlebe Bayern",
        },
        {
          type: "image",
          orientation: "portrait",
          src: "https://lonelyplanetstatic.imgix.net/marketing/best-in-travel/2025/categories/regions/bavaria/hand-beets-bowl-chickpeas-portrait.jpg",
          alt: "A hand reaching in to a small red striped bowl of beets and chickpeas",
          credit: "Thomas Linkel/Erlebe Bayern",
        },
        {
          _comment: "#4 desktop",
          type: "image",
          src: "https://lonelyplanetstatic.imgix.net/marketing/best-in-travel/2025/categories/regions/bavaria/man-back-hat-suspenders-tent.jpg",
          alt: "The back of a man's head with a brushlike top inside a tent",
          credit: "Thomas Linkel/Erlebe Bayern",
        },
        {
          type: "image",
          orientation: "portrait",
          src: "https://lonelyplanetstatic.imgix.net/marketing/best-in-travel/2025/categories/regions/bavaria/tents-people-river-reflection-portrait.jpg",
          alt: "Tents by a river with people eating underneath them and the scene reflected in the water",
          credit: "Thomas Linkel/Erlebe Bayern",
        },
        {
          type: "image",
          src: "https://lonelyplanetstatic.imgix.net/marketing/best-in-travel/2025/categories/regions/bavaria/tents-people-river-reflection.jpg",
          alt: "Tents by a river with people eating underneath them and the scene reflected in the water",
          credit: "Thomas Linkel/Erlebe Bayern",
        },
      ],
      articles: [
        "/articles/top-things-to-do-in-bavaria",
        "/articles/best-castles-bavaria",
        "/articles/best-beer-festivals-bavaria",
        "/articles/things-to-know-before-traveling-to-bavaria",
      ],
    },
    {
      name: "East Anglia",
      slug: "/england",
      saily: "https://go.saily.site/aff_c?offer_id=101&aff_id=5957&url_id=1344",
      meta: {
        category: "regions",
        parent: "England",
        tags: [],
        coords: {
          lat: 52.57587496,
          lon: 0.87698638,
        },
      },
      editorial: [
        {
          type: "p",
          children:
            "East Anglia offers a taste of traditional England without the typical touristy attractions. Comprising Suffolk, Norfolk, Essex and Cambridgeshire, this region features charming villages with 16th-century half-timbered houses, artistic wool towns and a coastline adorned with beaches and bird reserves where the sounds of bitterns can be heard in spring.",
        },
        {
          type: "ol",
          children: [
            "Listen as the wind amongst the reeds creates a soul-soothing soundtrack at birding hot spots such as Minsmere.",
            "University-hop in Cambridge, visiting legendary libraries, exceptional museums and college greens.",
            "Have a night out in Norwich, Norfolk’s cultured capital, enjoying theater, live music, art shows and creative dining.",
          ],
        },
        {
          type: "blockquote",
          children: [
            "I love the quirky market towns with unusual shops and a real community vibe. There are also spectacular places to explore nature, such as Minsmere and Dersingham Bog and some absolutely stunning scenery.",
            "Annette Salkald, North Suffolk Coast Reserves warden, Royal Society for the Protection of Birds",
          ],
        },
      ],
      media: [
        {
          type: "image",
          orientation: "portrait",
          src: "https://lonelyplanetstatic.imgix.net/marketing/best-in-travel/2025/categories/regions/east-anglia/sailboat-birds-sail-river-portrait.jpg",
          alt: "A sailboat with its sail up on a river with birds flying on the right",
          credit: "Jonathan Stokes for ������ϲʼ�ʱ����",
        },
        {
          type: "image",
          src: "https://lonelyplanetstatic.imgix.net/marketing/best-in-travel/2025/categories/regions/east-anglia/sailboat-birds-sail-river.jpg",
          alt: "A sailboat with its sail up on a river with birds flying on the right",
          credit: "Jonathan Stokes for ������ϲʼ�ʱ����",
        },
        {
          type: "image",
          orientation: "portrait",
          src: "https://lonelyplanetstatic.imgix.net/marketing/best-in-travel/2025/categories/regions/east-anglia/small-shacks-beach-colorful-portrait.jpg",
          alt: "A blue gradient sky with small beach shacks and grass on the sand",
          credit: "Sophie Knight/Kintzing",
        },
        {
          type: "image",
          src: "https://lonelyplanetstatic.imgix.net/marketing/best-in-travel/2025/categories/regions/east-anglia/small-shacks-beach-colorful.jpg",
          alt: "A blue gradient sky with small beach shacks and grass on the sand",
          credit: "Sophie Knight/Kintzing",
        },
        {
          type: "image",
          orientation: "portrait",
          src: "https://lonelyplanetstatic.imgix.net/marketing/best-in-travel/2025/categories/regions/east-anglia/windmill-reflection-water-portrait.jpg",
          alt: "A windmill reflected in the water with trees and bushes at sunset",
          credit: "Jonathan Stokes for ������ϲʼ�ʱ����",
        },
        {
          type: "image",
          src: "https://lonelyplanetstatic.imgix.net/marketing/best-in-travel/2025/categories/regions/east-anglia/windmill-reflection-water.jpg",
          alt: "A windmill reflected in the water with trees and bushes at sunset",
          credit: "Jonathan Stokes for ������ϲʼ�ʱ����",
        },
        {
          type: "image",
          orientation: "portrait",
          src: "https://lonelyplanetstatic.imgix.net/marketing/best-in-travel/2025/categories/regions/east-anglia/hand-fries-fish-beer-portrait.jpg",
          alt: "A hand reaches into the frame to spear fried food with a toothpick on pebbles with beer",
          credit: "Sophie Knight/Kintzing",
        },
        {
          type: "image",
          src: "https://lonelyplanetstatic.imgix.net/marketing/best-in-travel/2025/categories/regions/east-anglia/hand-fries-fish-beer.jpg",
          alt: "A hand reaches into the frame to spear fried food with a toothpick on pebbles with beer",
          credit: "Sophie Knight/Kintzing",
        },
        {
          type: "image",
          orientation: "portrait",
          src: "https://lonelyplanetstatic.imgix.net/marketing/best-in-travel/2025/categories/regions/east-anglia/red-sail-boat-river-trees-portrait.jpg",
          alt: "A small red sailboat on a river surrounded by trees",
          credit: "Jonathan Stokes for ������ϲʼ�ʱ����",
        },
        {
          type: "image",
          src: "https://lonelyplanetstatic.imgix.net/marketing/best-in-travel/2025/categories/regions/east-anglia/red-sail-boat-river-trees.jpg",
          alt: "A small red sailboat on a river surrounded by trees",
          credit: "Jonathan Stokes for ������ϲʼ�ʱ����",
        },
      ],
      articles: [
        "/articles/top-things-to-do-in-east-anglia",
        "/articles/best-time-to-visit-england",
        "/articles/things-to-know-england",
        "/articles/best-independent-shops-cambridge",
      ],
    },
    {
      name: "Jordan Trail",
      slug: "/jordan",
      saily: "https://go.saily.site/aff_c?offer_id=101&aff_id=5957&url_id=1344",
      meta: {
        category: "regions",
        parent: "Middle East",
        tags: [],
        coords: {
          lat: 31.00601087,
          lon: 35.71110033,
        },
      },
      editorial: [
        {
          type: "p",
          children:
            "Few people head to the Middle East to go hiking, but 10 years after the opening of the Jordan Trail, that’s changing. Stretching 676km (420 miles), walking this path is the perfect way to discover the beauty of Jordan, to see its epic sites and to experience its legendary hospitality.",
        },
        {
          type: "ol",
          children: [
            "Begin (or end) a Jordan Trail hike with a meal at Hashem, a legendary alfresco restaurant in the Jordanian capital, Amman – don’t miss the falafel.",
            "Pass through the outpost of Little Petra, a Nabataean settlement around 8km (5 miles) north of Petra itself.",
            "Drink sugary cups of mint tea by the trailside – good fuel for the gruelling ascents of the Jordan Trail.",
          ],
        },
        {
          type: "blockquote",
          children: [
            "If you are a Bedouin, having the freedom to move and go where you want is essential. Walking is my life – walk with the right people and you will always smile.",
            "Mohammed Al Homran, shepherd and Jordan Trail guide",
          ],
        },
      ],
      media: [
        {
          type: "image",
          orientation: "portrait",
          src: "https://lonelyplanetstatic.imgix.net/marketing/best-in-travel/2025/categories/regions/jordan-trail/man-camping-fire-mountains-sunset-portrait.jpg",
          alt: "A man with a campfire in a desert mountain setting at sunset",
          credit: "Justin Foulkes for ������ϲʼ�ʱ����",
        },
        {
          type: "image",
          src: "https://lonelyplanetstatic.imgix.net/marketing/best-in-travel/2025/categories/regions/jordan-trail/man-camping-fire-mountains-sunset.jpg",
          alt: "A man with a campfire in a desert mountain setting at sunset",
          credit: "Justin Foulkes for ������ϲʼ�ʱ����",
        },
        {
          type: "image",
          orientation: "portrait",
          src: "https://lonelyplanetstatic.imgix.net/marketing/best-in-travel/2025/categories/regions/jordan-trail/two-people-walking-desert-portrait.jpg",
          alt: "Two people walking in the desert with a view and trees",
          credit: "Justin Foulkes for ������ϲʼ�ʱ����",
        },
        {
          type: "image",
          src: "https://lonelyplanetstatic.imgix.net/marketing/best-in-travel/2025/categories/regions/jordan-trail/two-people-walking-desert.jpg",
          alt: "Two people walking in the desert with a view and trees",
          credit: "Justin Foulkes for ������ϲʼ�ʱ����",
        },
        {
          type: "image",
          orientation: "portrait",
          src: "https://lonelyplanetstatic.imgix.net/marketing/best-in-travel/2025/categories/regions/jordan-trail/three-people-walking-gorge-portrait.jpg",
          alt: "Three people walking in between large walls in a desert setting",
          credit: "Justin Foulkes for ������ϲʼ�ʱ����",
        },
        {
          type: "image",
          src: "https://lonelyplanetstatic.imgix.net/marketing/best-in-travel/2025/categories/regions/jordan-trail/three-people-walking-gorge.jpg",
          alt: "Three people walking in between large walls in a desert setting",
          credit: "Justin Foulkes for ������ϲʼ�ʱ����",
        },
        {
          type: "image",
          orientation: "portrait",
          src: "https://lonelyplanetstatic.imgix.net/marketing/best-in-travel/2025/categories/regions/jordan-trail/man-standing-mountains-cliff-portrait.jpg",
          alt: "A man standing and looking out over a cliff and mountains in the desert",
          credit: "Justin Foulkes for ������ϲʼ�ʱ����",
        },
        {
          type: "image",
          src: "https://lonelyplanetstatic.imgix.net/marketing/best-in-travel/2025/categories/regions/jordan-trail/man-standing-mountains-cliff.jpg",
          alt: "A man standing and looking out over a cliff and mountains in the desert",
          credit: "Justin Foulkes for ������ϲʼ�ʱ����",
        },
        {
          _comment: "#5 mobile",
          type: "image",
          orientation: "portrait",
          src: "https://lonelyplanetstatic.imgix.net/marketing/best-in-travel/2025/categories/regions/jordan-trail/man-brewing-tea-open-fire-camping-portrait.jpg",
          alt: "A man brewing tea over an open fire while camping and smiling",
          credit: "Justin Foulkes for ������ϲʼ�ʱ����",
        },
        {
          _comment: "#5 desktop",
          type: "image",
          src: "https://lonelyplanetstatic.imgix.net/marketing/best-in-travel/2025/categories/regions/jordan-trail/big-cliffs-right.jpg",
          alt: "A trail down below with big cliffs on the right and a hazy sky",
          credit: "Justin Foulkes for ������ϲʼ�ʱ����",
        },
      ],
      articles: [
        "/articles/jordan-trail",
        "/articles/responsible-travel-petra",
        "/articles/top-things-to-do-in-jordan",
      ],
    },
    {
      name: "Mount Hood and Columbia River Gorge",
      slug: "/usa/oregon/columbia-river-gorge",
      saily: "https://go.saily.site/aff_c?offer_id=101&aff_id=5957&url_id=1356",
      meta: {
        category: "regions",
        parent: "USA",
        tags: [],
        coords: {
          lat: 44.99968705,
          lon: -122.5987714,
        },
      },
      editorial: [
        {
          type: "p",
          children:
            "Only 40 minutes from Portland, Mt Hood and the Columbia River Gorge represent the best of the Pacific Northwest (PNW to locals). Being outside is a way of life, and adventures abound, whether you cycle or ski. Also be sure to enjoy the bounty of its farm stands, wineries and breweries.",
        },
        {
          type: "ol",
          children: [
            'Seek out Hiyu Wine Farm, a vineyard-and-restaurant offering natural wines made using the "field blends" technique.',
            "Hike Multnomah Falls, where the views are stunning year-round and trails lead visitors to several other waterfalls.",
            "Try windsurfing in the fun, outdoorsy town of Hood River, known as the windsurfing capital of the world.",
          ],
        },
        {
          type: "blockquote",
          children: [
            "Hug a giant evergreen, sit by a crisp river, stop to admire a tiny mushroom emerging from a stump. Whatever path you choose, be prepared for the sensational feeling of awe.",
            "Amanda Parrott, Hood River resident",
          ],
        },
      ],
      media: [
        {
          type: "image",
          orientation: "portrait",
          src: "https://lonelyplanetstatic.imgix.net/marketing/best-in-travel/2025/categories/regions/mount-hood-and-columbia-river-gorge/mountain-flowers-green-portrait.jpg",
          alt: "A view of a mountain through flowers",
          credit: "Will Matsuda",
        },
        {
          type: "image",
          src: "https://lonelyplanetstatic.imgix.net/marketing/best-in-travel/2025/categories/regions/mount-hood-and-columbia-river-gorge/mountain-flowers-green.jpg",
          alt: "A view of a mountain through flowers",
          credit: "Will Matsuda",
        },
        {
          type: "image",
          orientation: "portrait",
          src: "https://lonelyplanetstatic.imgix.net/marketing/best-in-travel/2025/categories/regions/mount-hood-and-columbia-river-gorge/skiier-skiing-snow-trees-portrait.jpg",
          alt: "A skier in black skiing clothes going down a mountain with snow covered trees",
          credit: "Will Matsuda",
        },
        {
          type: "image",
          src: "https://lonelyplanetstatic.imgix.net/marketing/best-in-travel/2025/categories/regions/mount-hood-and-columbia-river-gorge/skiier-skiing-snow-trees.jpg",
          alt: "A skier in black skiing clothes going down a mountain with snow covered trees",
          credit: "Will Matsuda",
        },
        {
          type: "image",
          orientation: "portrait",
          src: "https://lonelyplanetstatic.imgix.net/marketing/best-in-travel/2025/categories/regions/mount-hood-and-columbia-river-gorge/backpackers-backpack-hiking-mountain-portrait.jpg",
          alt: "Two backpackers walking along from behind with camping gear and a mountain in the background",
          credit: "Will Matsuda",
        },
        {
          type: "image",
          src: "https://lonelyplanetstatic.imgix.net/marketing/best-in-travel/2025/categories/regions/mount-hood-and-columbia-river-gorge/backpackers-backpack-hiking-mountain.jpg",
          alt: "Two backpackers walking along from behind with camping gear and a mountain in the background",
          credit: "Will Matsuda",
        },
        {
          type: "image",
          orientation: "portrait",
          src: "https://lonelyplanetstatic.imgix.net/marketing/best-in-travel/2025/categories/regions/mount-hood-and-columbia-river-gorge/road-cliff-green-trees-sky-portrait.jpg",
          alt: "A road cutting through a pine forest with a cliff on the right hand side and sky and mountains in the distance",
          credit: "Dave Alan/Getty Images",
        },
        {
          type: "image",
          src: "https://lonelyplanetstatic.imgix.net/marketing/best-in-travel/2025/categories/regions/mount-hood-and-columbia-river-gorge/road-cliff-green-trees-sky.jpg",
          alt: "A road cutting through a pine forest with a cliff on the right hand side and sky and mountains in the distance",
          credit: "Dave Alan/Getty Images",
        },
        {
          type: "image",
          orientation: "portrait",
          src: "https://lonelyplanetstatic.imgix.net/marketing/best-in-travel/2025/categories/regions/mount-hood-and-columbia-river-gorge/butte-cliff-river-cloud-sky-portrait.jpg",
          alt: "A butte with blue sky and a river down the side of a cliff",
          credit: "Gary Quay/Getty Images",
        },
        {
          type: "image",
          src: "https://lonelyplanetstatic.imgix.net/marketing/best-in-travel/2025/categories/regions/mount-hood-and-columbia-river-gorge/butte-cliff-river-cloud-sky.jpg",
          alt: "A butte with blue sky and a river down the side of a cliff",
          credit: "Gary Quay/Getty Images",
        },
      ],
      articles: [
        "/articles/weekend-itinerary-columbia-river-gorge",
        "/articles/best-time-to-visit-oregon",
      ],
    },

    // CITIES
    {
      name: "Toulouse",
      slug: "/france/toulouse-gers-vallee-du-tarn",
      saily: "https://go.saily.site/aff_c?offer_id=101&aff_id=5957&url_id=1350",
      meta: {
        category: "cities",
        parent: "France",
        tags: [],
        coords: {
          lat: 43.62190478,
          lon: 1.44798086,
        },
      },
      editorial: [
        {
          type: "p",
          children:
            'Often referred to as "Paris in miniature," Toulouse is a maze-like city overflowing with art galleries in repurposed industrial spaces, excellent food and scenic river and canal banks. Time your visit for the 2025 reopening of Musée des Augustins, a 14th-century cloistered convent that has been a beacon of Toulouse\'s art scene since 1795.',
        },
        {
          type: "ol",
          children: [
            "Catch a live gig at Le Saint des Seins, jazz at Le Taquin or classical music at Théâtre du Capitole – Toulouse is a UNESCO City of Music after all.",
            "Take an architectural tour and don’t miss Romanesque Basilique St-Sernin and neoclassical landmark Capitole.",
            "Shop for a picnic of local produce at Marché Victor Hugo or grab a cassoulet lunch in an eatery above the covered market.",
          ],
        },
        {
          type: "blockquote",
          children: [
            "The 9m-tall (29.5ft) mechanical Minotaur is an emblematic figure who resonates with the image of Toulouse, a labyrinthine city with incredible cultural projects, heritage and excellent food.",
            "François Delarozière, artistic director, Halle de la Machine",
          ],
        },
      ],
      media: [
        {
          type: "image",
          orientation: "portrait",
          src: "https://lonelyplanetstatic.imgix.net/marketing/best-in-travel/2025/categories/cities/toulouse/woman-flower-dress-boat-canal-portrait.jpg",
          alt: "A woman in a floral dress steers a boat down a canal with trees overhead",
          credit: "Matt Munro for ������ϲʼ�ʱ����",
        },
        {
          type: "image",
          src: "https://lonelyplanetstatic.imgix.net/marketing/best-in-travel/2025/categories/cities/toulouse/woman-flower-dress-boat-canal.jpg",
          alt: "A woman in a floral dress steers a boat down a canal with trees overhead",
          credit: "Matt Munro for ������ϲʼ�ʱ����",
        },
        {
          type: "image",
          orientation: "portrait",
          src: "https://lonelyplanetstatic.imgix.net/marketing/best-in-travel/2025/categories/cities/toulouse/man-building-art-painting-portrait.jpg",
          alt: "A man stands in front of a church facade with large doors and a painting overhead",
          credit: "Jerome Labouyrie/Shutterstock",
        },
        {
          type: "image",
          src: "https://lonelyplanetstatic.imgix.net/marketing/best-in-travel/2025/categories/cities/toulouse/man-building-art-painting.jpg",
          alt: "A man stands in front of a church facade with large doors and a painting overhead",
          credit: "Jerome Labouyrie/Shutterstock",
        },
        {
          type: "image",
          orientation: "portrait",
          src: "https://lonelyplanetstatic.imgix.net/marketing/best-in-travel/2025/categories/cities/toulouse/people-sitting-trees-building-portrait.jpg",
          alt: "People gather in a square in the shade with trees and buildings behind them",
          credit: "Richard Johnson/Darklight x Kintzing",
        },
        {
          type: "image",
          src: "https://lonelyplanetstatic.imgix.net/marketing/best-in-travel/2025/categories/cities/toulouse/people-sitting-trees-building.jpg",
          alt: "People gather in a square in the shade with trees and buildings behind them",
          credit: "Richard Johnson/Darklight x Kintzing",
        },
        {
          type: "image",
          orientation: "portrait",
          src: "https://lonelyplanetstatic.imgix.net/marketing/best-in-travel/2025/categories/cities/toulouse/building-people-sky-portrait.jpg",
          alt: "People walk in front of an ornate government building with flags",
          credit: "Mario Guti/Getty Images",
        },
        {
          type: "image",
          src: "https://lonelyplanetstatic.imgix.net/marketing/best-in-travel/2025/categories/cities/toulouse/building-people-sky.jpg",
          alt: "People walk in front of an ornate government building with flags",
          credit: "Mario Guti/Getty Images",
        },
        {
          type: "image",
          orientation: "portrait",
          src: "https://lonelyplanetstatic.imgix.net/marketing/best-in-travel/2025/categories/cities/toulouse/reflection-water-building-portrait.jpg",
          alt: "A reflection of a steeple in the water",
          credit: "Mario Guti/Getty Images",
        },
        {
          type: "image",
          src: "https://lonelyplanetstatic.imgix.net/marketing/best-in-travel/2025/categories/cities/toulouse/reflection-water-building.jpg",
          alt: "A reflection of a steeple in the water",
          credit: "Mario Guti/Getty Images",
        },
      ],
      articles: [
        "/articles/top-things-to-do-in-toulouse-france",
        "/articles/best-time-to-visit-toulouse-france",
        "/articles/where-to-eat-toulouse",
      ],
    },
    {
      name: "Puducherry (Pondicherry)",
      slug: "/india/tamil-nadu/puducherry-pondicherry",
      saily: "https://go.saily.site/aff_c?offer_id=101&aff_id=5957&url_id=1344",
      meta: {
        category: "cities",
        parent: "India",
        tags: [],
        coords: {
          lat: 11.93499371,
          lon: 79.83000037,
        },
      },
      editorial: [
        {
          type: "p",
          children:
            "Located on the Bay of Bengal in South India, Puducherry (formerly Pondicherry) has attracted visitors with its Gallic architecture, reflecting its history as a French colony until 1954, as well as its temples and spiritual centers. Now the city is beckoning beachgoers, thanks to an ongoing effort to restore the sandy coastline.",
        },
        {
          type: "ol",
          children: [
            "Take a stroll through historic White Town, with its pastel-hued buildings that could just as easily be on the French Riviera.",
            "Check out the everchanging displays at the government-run Puducherry Contemporary Art Gallery, which opened in 2022.",
            "See artefacts from the Chola and Vijayanagar empires displayed alongside elegant French antiques at the Puducherry Museum.",
          ],
        },
        {
          type: "blockquote",
          children: [
            "Puducherry is rooted in its heritage yet cosmopolitan, with thousand-year-old Kolam rituals, games of pétanque by the bay, basilica bells and experimental sonic artists who invent their own instruments, all in one place.",
            "Sid Saikia, innkeeper, Gratitude Heritage",
          ],
        },
      ],
      media: [
        {
          type: "image",
          orientation: "portrait",
          src: "https://lonelyplanetstatic.imgix.net/marketing/best-in-travel/2025/categories/cities/puducherry/two-women-saris-seashore-portrait.jpg",
          alt: "Two women facing away from the camera in colorful clothes at a beach",
          credit: "Gabriela Bhaskar for ������ϲʼ�ʱ����",
        },
        {
          type: "image",
          src: "https://lonelyplanetstatic.imgix.net/marketing/best-in-travel/2025/categories/cities/puducherry/two-women-saris-seashore.jpg",
          alt: "Two women facing away from the camera in colorful clothes at a beach",
          credit: "Gabriela Bhaskar for ������ϲʼ�ʱ����",
        },
        {
          type: "image",
          orientation: "portrait",
          src: "https://lonelyplanetstatic.imgix.net/marketing/best-in-travel/2025/categories/cities/puducherry/hands-pomegranate-market-portrait.jpg",
          alt: "Hands opening pomegranate seeds on a pile of pomegranates at market",
          credit: "Gabriela Bhaskar for ������ϲʼ�ʱ����",
        },
        {
          type: "image",
          src: "https://lonelyplanetstatic.imgix.net/marketing/best-in-travel/2025/categories/cities/puducherry/hands-pomegranate-market.jpg",
          alt: "Hands opening pomegranate seeds on a pile of pomegranates at market",
          credit: "Gabriela Bhaskar for ������ϲʼ�ʱ����",
        },
        {
          type: "image",
          orientation: "portrait",
          src: "https://lonelyplanetstatic.imgix.net/marketing/best-in-travel/2025/categories/cities/puducherry/statue-gazebo-people-umbrella-portrait.jpg",
          alt: "People under an umbrella on a sunny day admiring a statue in a gazebo",
          credit: "Gabriela Bhaskar for ������ϲʼ�ʱ����",
        },
        {
          type: "image",
          src: "https://lonelyplanetstatic.imgix.net/marketing/best-in-travel/2025/categories/cities/puducherry/statue-gazebo-people-umbrella.jpg",
          alt: "People under an umbrella on a sunny day admiring a statue in a gazebo",
          credit: "Gabriela Bhaskar for ������ϲʼ�ʱ����",
        },
        {
          type: "image",
          orientation: "portrait",
          src: "https://lonelyplanetstatic.imgix.net/marketing/best-in-travel/2025/categories/cities/puducherry/building-gold-architecture-trees-portrait.jpg",
          alt: "A large gold building with interesting architecture with trees in the foreground",
          credit: "Gabriela Bhaskar for ������ϲʼ�ʱ����",
        },
        {
          type: "image",
          src: "https://lonelyplanetstatic.imgix.net/marketing/best-in-travel/2025/categories/cities/puducherry/building-gold-architecture-trees.jpg",
          alt: "A large gold building with interesting architecture with trees in the foreground",
          credit: "Gabriela Bhaskar for ������ϲʼ�ʱ����",
        },
        {
          type: "image",
          orientation: "portrait",
          src: "https://lonelyplanetstatic.imgix.net/marketing/best-in-travel/2025/categories/cities/puducherry/street-city-people-bike-portrait.jpg",
          alt: "A street scene of people standing in front of a pink building and talking while a bike goes by",
          credit: "Gabriela Bhaskar for ������ϲʼ�ʱ����",
        },
        {
          type: "image",
          src: "https://lonelyplanetstatic.imgix.net/marketing/best-in-travel/2025/categories/cities/puducherry/street-city-people-bike.jpg",
          alt: "A street scene of people standing in front of a pink building and talking while a bike goes by",
          credit: "Gabriela Bhaskar for ������ϲʼ�ʱ����",
        },
      ],
      articles: [
        "/articles/first-time-guide-to-pondicherry",
        "/articles/best-time-to-visit-india",
        "/articles/best-places-to-visit-in-india",
      ],
    },
    {
      name: "Bansko",
      slug: "/bulgaria",
      saily: "https://go.saily.site/aff_c?offer_id=101&aff_id=5957&url_id=1344",
      meta: {
        category: "cities",
        parent: "Bulgaria",
        tags: [],
        coords: {
          lat: 41.99377133,
          lon: 23.72039374,
        },
      },
      editorial: [
        {
          type: "p",
          children:
            "Known for being a winter resort town, Bansko is in the midst of a transformation into a year-round destination, for both outdoor experiences and location-curious professionals. This charming Bulgarian village has become one of the world’s top hubs for digital nomads, attracting remote workers and adventure lovers in equal measure.",
        },
        {
          type: "ol",
          children: [
            "Breakfast on a banitsa – a traditional morning pastry with cheese – at Samun bakery.",
            "Embrace winter in Bansko and go skiing on the slopes from December to April.",
            "Support a local gem at The Art of Irina – this Bansko artist is known for her satirical paintings that serve as a unique souvenir from your travels.",
          ],
        },
        {
          type: "blockquote",
          children: [
            "We love that Bansko feels like a village in the middle of nature yet hosts the most diverse variety of people from all backgrounds – a baba (grandma) in the street, a local family, world-schoolers, nomads.",
            "Anne and Christian, owners of The French Guy Bakery",
          ],
        },
      ],
      media: [
        {
          type: "image",
          orientation: "portrait",
          src: "https://lonelyplanetstatic.imgix.net/marketing/best-in-travel/2025/categories/cities/bansko/snowy-river-surrounded-by-snow-portrait.jpg",
          alt: "A river flowing towards the camera surrounded by snow with mountains in the distance",
          credit: "Shutterstock",
        },
        {
          type: "image",
          src: "https://lonelyplanetstatic.imgix.net/marketing/best-in-travel/2025/categories/cities/bansko/snowy-river-surrounded-by-snow.jpg",
          alt: "A river flowing towards the camera surrounded by snow with mountains in the distance",
          credit: "Shutterstock",
        },
        {
          type: "image",
          orientation: "portrait",
          src: "https://lonelyplanetstatic.imgix.net/marketing/best-in-travel/2025/categories/cities/bansko/hiker-at-dawn-on-snowy-peak-portrait.jpg",
          alt: "A hiker in a red jacket at dawn on a snowy peak with the sunrise in the background",
          credit: "Maya Karkalicheva/Getty Images",
        },
        {
          type: "image",
          src: "https://lonelyplanetstatic.imgix.net/marketing/best-in-travel/2025/categories/cities/bansko/hiker-at-dawn-on-snowy-peak.jpg",
          alt: "A hiker in a red jacket at dawn on a snowy peak with the sunrise in the background",
          credit: "Maya Karkalicheva/Getty Images",
        },
        {
          type: "image",
          orientation: "portrait",
          src: "https://lonelyplanetstatic.imgix.net/marketing/best-in-travel/2025/categories/cities/bansko/train-snow-blue-sky-portrait.jpg",
          alt: "A train on tracks through a snowy landscape with trees and mountains",
          credit: "Shutterstock",
        },
        {
          type: "image",
          src: "https://lonelyplanetstatic.imgix.net/marketing/best-in-travel/2025/categories/cities/bansko/train-snow-blue-sky.jpg",
          alt: "A train on tracks through a snowy landscape with trees and mountains",
          credit: "Shutterstock",
        },
        {
          type: "image",
          orientation: "portrait",
          src: "https://lonelyplanetstatic.imgix.net/marketing/best-in-travel/2025/categories/cities/bansko/skiers-slope-ski-run-trees-portrait.jpg",
          alt: "Skiiers on a slope surrounded by trees and mountains",
          credit: "Shutterstock",
        },
        {
          type: "image",
          src: "https://lonelyplanetstatic.imgix.net/marketing/best-in-travel/2025/categories/cities/bansko/skiers-slope-ski-run-trees.jpg",
          alt: "Skiiers on a slope surrounded by trees and mountains",
          credit: "Shutterstock",
        },
        {
          type: "image",
          orientation: "portrait",
          src: "https://lonelyplanetstatic.imgix.net/marketing/best-in-travel/2025/categories/cities/bansko/trees-hiking-path-portrait.jpg",
          alt: "A hiking path with trees on either side",
          credit: "Shutterstock",
        },
        {
          type: "image",
          src: "https://lonelyplanetstatic.imgix.net/marketing/best-in-travel/2025/categories/cities/bansko/trees-hiking-path.jpg",
          alt: "A hiking path with trees on either side",
          credit: "Shutterstock",
        },
      ],
      articles: [
        "/articles/best-things-to-do-in-bansko-bulgaria",
        "/articles/first-time-guide-bulgaria",
        "/articles/best-beaches-in-bulgaria",
      ],
    },
    {
      name: "Chiang Mai",
      slug: "/thailand/chiang-mai-province/chiang-mai",
      saily: "https://go.saily.site/aff_c?offer_id=101&aff_id=5957&url_id=1347",
      meta: {
        category: "cities",
        parent: "Thailand",
        tags: [],
        coords: {
          lat: 18.7999752,
          lon: 98.98004594,
        },
      },
      editorial: [
        {
          type: "p",
          children:
            "Chiang Mai thrills with its elegant blending of distinctive flavors. In this city, greetings often revolve around whether you've eaten yet, demonstrating the importance of food in Thai culture. From passionate discussions about the best vegetable for a basil stir-fry to perfecting intricate layers of sweet, sour, salty and spicy tastes, Thai cuisine celebrates the taste of complexity.",
        },
        {
          type: "ol",
          children: [
            "Experience Chiang Mai Food Festival, a yearly event held in January celebrating the best food the city has to offer.",
            "Wander Friday morning’s Kad Baan Hor Market where Yunnanese Muslims and tribespeople sell food unlike anything else in the city.",
            "Belly up to the all-you-can-eat khantoke dinner, accompanied by traditional theater performances, at Old Chiang Mai Cultural Centre.",
          ],
        },
        {
          type: "blockquote",
          children: [
            "Northern Laap spicy meat salad incorporates cumin, blood and bile. It’s recently gone from dying dish to number one choice for hungry teens. Try Laap Bunker or Laap Duang Dee Mee Suk for an authentic taste.",
            "Govit Thatarat, founder, Review Chiang Mai",
          ],
        },
      ],
      media: [
        {
          type: "video",
          orientation: "landscape",
          credit: "Best In Travel 2025",
          src: {
            poster:
              "https://lonelyplanetstatic.imgix.net/marketing/best-in-travel/2025/videos/chiang-mai/chiang-mai-trailer-landscape.png",
            mp4: "https://lonelyplanetstatic.imgix.net/marketing/best-in-travel/2025/videos/chiang-mai/chiang-mai-trailer-landscape.mp4",
            webm: "https://lonelyplanetstatic.imgix.net/marketing/best-in-travel/2025/videos/chiang-mai/chiang-mai-trailer-landscape.webm",
            jwvid: "4eFhLfOd",
          },
        },
        {
          type: "video",
          orientation: "portrait",
          credit: "Best In Travel 2025",
          src: {
            poster:
              "https://lonelyplanetstatic.imgix.net/marketing/best-in-travel/2025/videos/chiang-mai/chiang-mai-trailer-portrait.png",
            mp4: "https://lonelyplanetstatic.imgix.net/marketing/best-in-travel/2025/videos/chiang-mai/chiang-mai-trailer-portrait.mp4",
            webm: "https://lonelyplanetstatic.imgix.net/marketing/best-in-travel/2025/videos/chiang-mai/chiang-mai-trailer-portrait.webm",
            jwvid: "9ro4Ojsx",
          },
        },

        {
          type: "image",
          orientation: "portrait",
          src: "https://lonelyplanetstatic.imgix.net/marketing/best-in-travel/2025/categories/cities/chiang-mai/stall-vendor-street-food-women-portrait.jpg",
          alt: "A woman waits at a street food stall with multiple food options and steam coming off of the food",
          credit: "Getty Images",
        },
        {
          type: "image",
          src: "https://lonelyplanetstatic.imgix.net/marketing/best-in-travel/2025/categories/cities/chiang-mai/stall-vendor-street-food-women.jpg",
          alt: "A woman waits at a street food stall with multiple food options and steam coming off of the food",
          credit: "Getty Images",
        },
        {
          type: "image",
          orientation: "portrait",
          src: "https://lonelyplanetstatic.imgix.net/marketing/best-in-travel/2025/categories/cities/chiang-mai/trees-building-river-tourist-portrait.jpg",
          alt: "A tourist takes a photograph on a pathway with water on either side and trees in the foreground",
          credit: "Tanakrit Wattanasiri/239 Studio for ������ϲʼ�ʱ����",
        },
        {
          type: "image",
          src: "https://lonelyplanetstatic.imgix.net/marketing/best-in-travel/2025/categories/cities/chiang-mai/trees-building-river-tourist.jpg",
          alt: "A tourist takes a photograph on a pathway with water on either side and trees in the foreground",
          credit: "Tanakrit Wattanasiri/239 Studio for ������ϲʼ�ʱ����",
        },
        {
          type: "image",
          orientation: "portrait",
          src: "https://lonelyplanetstatic.imgix.net/marketing/best-in-travel/2025/categories/cities/chiang-mai/dancers-candles-group-women-portrait.jpg",
          alt: "A group of women perform a choreographed dance with candles",
          credit: "Tanakrit Wattanasiri/239 Studio for ������ϲʼ�ʱ����",
        },
        {
          type: "image",
          src: "https://lonelyplanetstatic.imgix.net/marketing/best-in-travel/2025/categories/cities/chiang-mai/dancers-candles-group-women.jpg",
          alt: "A group of women perform a choreographed dance with candles",
          credit: "Tanakrit Wattanasiri/239 Studio for ������ϲʼ�ʱ����",
        },
        {
          type: "image",
          orientation: "portrait",
          src: "https://lonelyplanetstatic.imgix.net/marketing/best-in-travel/2025/categories/cities/chiang-mai/eggs-breakfast-food-hand-plate-portrait.jpg",
          alt: "Breakfast",
          credit: "Tanakrit Wattanasiri/239 Studio for ������ϲʼ�ʱ����",
        },
        {
          type: "image",
          src: "https://lonelyplanetstatic.imgix.net/marketing/best-in-travel/2025/categories/cities/chiang-mai/eggs-breakfast-food-hand-plate.jpg",
          alt: "Breakfast",
          credit: "Tanakrit Wattanasiri/239 Studio for ������ϲʼ�ʱ����",
        },
      ],
      articles: [
        "/articles/where-to-eat-chiangmai",
        "/articles/chiang-mai-on-a-budget",
        "/articles/best-neighborhoods-in-chiang-mai",
        "/articles/best-time-to-visit-chiang-mai",
      ],
    },
    {
      name: "Genoa",
      slug: "/italy/liguria-piedmont-and-valle-daosta/genoa",
      saily: "https://go.saily.site/aff_c?offer_id=101&aff_id=5957&url_id=1346",
      meta: {
        category: "cities",
        parent: "Italy",
        tags: [],
        coords: {
          lat: 44.40998822,
          lon: 8.93003861,
        },
      },
      editorial: [
        {
          type: "p",
          children:
            "Few places in Italy are “undiscovered,” but Genoa feels like a treasured secret. With a new high-speed-train connection in the works, now is the time to seek out this stately city with superlative culinary chops, a stunning seaside and a well-earned reputation as one of Italy’s most historically and culturally vibrant cities.",
        },
        {
          type: "ol",
          children: [
            "Wander through the alleyways known locally as caruggi to capture the soul of the city and discover the sumptuous Palazzi dei Rolli.",
            "Indulge in endless versions of pesto (ideally on trofie pasta or focaccia) at the Mercato Orientale – and everywhere else.",
            "Walk the coastline to tiny Boccadasse and experience Cinque Terre vibes without leaving the city.",
          ],
        },
        {
          type: "blockquote",
          children: [
            "There’s something special about Genoa. Like many families, we moved abroad to find new opportunities, before, like many families, we realized that everything we needed was waiting for us at home.",
            "Carolina, local pastry chef",
          ],
        },
      ],
      media: [
        {
          type: "video",
          orientation: "landscape",
          credit: "Best In Travel 2025",
          src: {
            poster:
              "https://lonelyplanetstatic.imgix.net/marketing/best-in-travel/2025/videos/genoa/genoa-trailer-landscape.png",
            mp4: "https://lonelyplanetstatic.imgix.net/marketing/best-in-travel/2025/videos/genoa/genoa-trailer-landscape.mp4",
            webm: "https://lonelyplanetstatic.imgix.net/marketing/best-in-travel/2025/videos/genoa/genoa-trailer-landscape.webm",
            jwvid: "7DTfx49i",
          },
        },
        {
          type: "video",
          orientation: "portrait",
          credit: "Best In Travel 2025",
          src: {
            poster:
              "https://lonelyplanetstatic.imgix.net/marketing/best-in-travel/2025/videos/genoa/genoa-trailer-portrait.png",
            mp4: "https://lonelyplanetstatic.imgix.net/marketing/best-in-travel/2025/videos/genoa/genoa-trailer-portrait.mp4",
            webm: "https://lonelyplanetstatic.imgix.net/marketing/best-in-travel/2025/videos/genoa/genoa-trailer-portrait.webm",
            jwvid: "fSej8GT8",
          },
        },
        {
          type: "image",
          orientation: "portrait",
          src: "https://lonelyplanetstatic.imgix.net/marketing/best-in-travel/2025/categories/cities/genoa/women-cafe-street-food-portrait.jpg",
          alt: "A woman and a man dine at a streetside cafe, other pedestrians walk by",
          credit: "Francesca Salamone for ������ϲʼ�ʱ����",
        },
        {
          type: "image",
          src: "https://lonelyplanetstatic.imgix.net/marketing/best-in-travel/2025/categories/cities/genoa/women-cafe-street-food.jpg",
          alt: "A woman and a man dine at a streetside cafe, other pedestrians walk by",
          credit: "Francesca Salamone for ������ϲʼ�ʱ����",
        },
        {
          type: "image",
          orientation: "portrait",
          src: "https://lonelyplanetstatic.imgix.net/marketing/best-in-travel/2025/categories/cities/genoa/city-windows-narrow-street-portrait.jpg",
          alt: "A street with open shutters and colorful buildings leading down to the water",
          credit: "Roman Sigaev/Shutterstock",
        },
        {
          type: "image",
          src: "https://lonelyplanetstatic.imgix.net/marketing/best-in-travel/2025/categories/cities/genoa/city-windows-narrow-street.jpg",
          alt: "A street with open shutters and colorful buildings leading down to the water",
          credit: "Roman Sigaev/Shutterstock",
        },
        {
          type: "image",
          orientation: "portrait",
          src: "https://lonelyplanetstatic.imgix.net/marketing/best-in-travel/2025/categories/cities/genoa/signs-italy-food-portrait.jpg",
          alt: "Signs advertising food hanging outside a market in Italy",
          credit: "Francesca Salamone for ������ϲʼ�ʱ����",
        },
        {
          type: "image",
          src: "https://lonelyplanetstatic.imgix.net/marketing/best-in-travel/2025/categories/cities/genoa/signs-italy-food.jpg",
          alt: "Signs advertising food hanging outside a market in Italy",
          credit: "Francesca Salamone for ������ϲʼ�ʱ����",
        },
        {
          type: "image",
          orientation: "portrait",
          src: "https://lonelyplanetstatic.imgix.net/marketing/best-in-travel/2025/categories/cities/genoa/newspaper-man-beach-rocks-portrait.jpg",
          alt: "An older man reclining on his back reading a newspaper on a beach of rocks",
          credit: "Géssica Hage/Untold Fable x Kintzing",
        },
        {
          type: "image",
          src: "https://lonelyplanetstatic.imgix.net/marketing/best-in-travel/2025/categories/cities/genoa/newspaper-man-beach-rocks.jpg",
          alt: "An older man reclining on his back reading a newspaper on a beach of rocks",
          credit: "Géssica Hage/Untold Fable x Kintzing",
        },
      ],
      articles: [
        "/articles/first-time-guide-to-genoa",
        "/articles/local-recommends-where-to-eat-and-drink-in-genoa",
      ],
    },
    {
      name: "Pittsburgh",
      slug: "/usa/pennsylvania/pittsburgh",
      saily: "https://go.saily.site/aff_c?offer_id=101&aff_id=5957&url_id=1356",
      meta: {
        category: "cities",
        parent: "USA",
        tags: [],
        coords: {
          lat: 40.43194445,
          lon: -80.00193125,
        },
      },
      editorial: [
        {
          type: "p",
          children:
            "What a feeling Pittsburgh inspires. This city effortlessly combines its industrial past with its artisanal present – its affordability and charm draws all manner of artists and creators. Walk around Butler St to witness the alchemy at local coffee shops, boutiques, independent restaurants and breweries. Soon you’ll understand Pittsburgh's particular brand of magic, at once disarming and familiar.",
        },
        {
          type: "ol",
          children: [
            "Pull up to a communal table in the beer hall of bright, cheerful Lorelei for an incredible cocktail or wood-fired pizza in East Liberty.",
            "Have fun at iconic amusement park Kennywood. Its century-old wooden roller coasters – yes, plural – are a must.",
            "Ride the Duquesne Incline up to Mt Washington for stunning perspectives of the city and the three rivers.",
          ],
        },
        {
          type: "blockquote",
          children: [
            "Pittsburgh’s affordability makes it accessible – for small business owners, for artists, for tech start-ups, for restaurateurs – and its accessibility makes it vibrant.",
            "Pete Kurzweg, owner of Lorelei, Independent Brewing and Hidden Harbor",
          ],
        },
      ],
      media: [
        {
          type: "video",
          orientation: "landscape",
          credit: "Best In Travel 2025",
          src: {
            poster:
              "https://lonelyplanetstatic.imgix.net/marketing/best-in-travel/2025/videos/pittsburgh/pittsburgh-trailer-landscape.jpg",
            mp4: "https://lonelyplanetstatic.imgix.net/marketing/best-in-travel/2025/videos/pittsburgh/pittsburgh-trailer-landscape.mp4",
            webm: "https://lonelyplanetstatic.imgix.net/marketing/best-in-travel/2025/videos/pittsburgh/pittsburgh-trailer-landscape.webm",
            jwvid: "Xmj8GGmO",
          },
        },
        {
          type: "video",
          orientation: "portrait",
          credit: "Best In Travel 2025",
          src: {
            poster:
              "https://lonelyplanetstatic.imgix.net/marketing/best-in-travel/2025/videos/pittsburgh/pittsburgh-trailer-portrait.jpg",
            mp4: "https://lonelyplanetstatic.imgix.net/marketing/best-in-travel/2025/videos/pittsburgh/pittsburgh-trailer-portrait.mp4",
            webm: "https://lonelyplanetstatic.imgix.net/marketing/best-in-travel/2025/videos/pittsburgh/pittsburgh-trailer-portrait.webm",
            jwvid: "PVQJyIGM",
          },
        },
        {
          type: "image",
          orientation: "portrait",
          src: "https://lonelyplanetstatic.imgix.net/marketing/best-in-travel/2025/categories/cities/pittsburgh/aerial-view-yellow-bridge-portrait.jpg",
          alt: "An aerial view of a city with a yellow bridge and river",
          credit: "Anthem Video for ������ϲʼ�ʱ����",
        },
        {
          type: "image",
          src: "https://lonelyplanetstatic.imgix.net/marketing/best-in-travel/2025/categories/cities/pittsburgh/aerial-view-yellow-bridge.jpg",
          alt: "An aerial view of a city with a yellow bridge and river",
          credit: "Anthem Video for ������ϲʼ�ʱ����",
        },
        {
          type: "image",
          orientation: "portrait",
          src: "https://lonelyplanetstatic.imgix.net/marketing/best-in-travel/2025/categories/cities/pittsburgh/display-models-dots-portrait.jpg",
          alt: "A museum display with dots and mannequins",
          credit: "Anthem Video for ������ϲʼ�ʱ����",
        },
        {
          type: "image",
          src: "https://lonelyplanetstatic.imgix.net/marketing/best-in-travel/2025/categories/cities/pittsburgh/display-models-dots.jpg",
          alt: "A museum display with dots and mannequins",
          credit: "Anthem Video for ������ϲʼ�ʱ����",
        },
        {
          type: "image",
          orientation: "portrait",
          src: "https://lonelyplanetstatic.imgix.net/marketing/best-in-travel/2025/categories/cities/pittsburgh/latte-art-hands-tattoos-portrait.jpg",
          alt: "An overhead view of a latte held with hands and tattoos",
          credit: "Anthem Video for ������ϲʼ�ʱ����",
        },
        {
          type: "image",
          src: "https://lonelyplanetstatic.imgix.net/marketing/best-in-travel/2025/categories/cities/pittsburgh/latte-art-hands-tattoos.jpg",
          alt: "An overhead view of a latte held with hands and tattoos",
          credit: "Anthem Video for ������ϲʼ�ʱ����",
        },
        {
          type: "image",
          orientation: "portrait",
          src: "https://lonelyplanetstatic.imgix.net/marketing/best-in-travel/2025/categories/cities/pittsburgh/garden-restaurant-trees-portrait.jpg",
          alt: "A restaurant garden with trees and people sitting",
          credit: "Cameron Croston for ������ϲʼ�ʱ����",
        },
        {
          type: "image",
          src: "https://lonelyplanetstatic.imgix.net/marketing/best-in-travel/2025/categories/cities/pittsburgh/garden-restaurant-trees.jpg",
          alt: "A restaurant garden with trees and people sitting",
          credit: "Cameron Croston for ������ϲʼ�ʱ����",
        },
      ],
      articles: [
        "/articles/ultimate-weekend-in-pittsburgh",
        "/articles/best-neighborhoods-in-pittsburgh",
        "/articles/best-day-trips-from-pittsburgh",
        "/articles/top-free-things-to-do-in-pittsburgh",
      ],
    },
    {
      name: "Osaka",
      slug: "/japan/kansai/osaka",
      saily: "https://go.saily.site/aff_c?offer_id=101&aff_id=5957&url_id=1348",
      meta: {
        category: "cities",
        parent: "Japan",
        tags: [],
        coords: {
          lat: 34.77593035,
          lon: 135.3438196,
        },
      },
      editorial: [
        {
          type: "p",
          children:
            "It’s apt that Expo, the world’s largest show of innovative design and scientific invention, is back in Osaka for a second time in 2025 – the city is, after all, one of the most open-minded and dynamic in Japan. Enjoy world-class museums and don't miss the city's tastiest Japanese dishes with delicious vegan, vegetarian and gluten-free options fairly easy to find.",
        },
        {
          type: "ol",
          children: [
            "Have your camera ready for Dōtonbori’s signs – the warm glow of this district’s neon lights will linger in your memory forever.",
            "Explore the majestic white fortress that is Osaka Castle – it’s a 1930s reconstruction, but impressive nonetheless, with an excellent museum inside.",
            "Soak in Spa World, an onsen (Japanese bath house) amusement park where hot water and a little kitsch combine.",
          ],
        },
        {
          type: "blockquote",
          children: [
            "The most distinct aspect about the city is its locals. Osakans are some of Japan’s most friendly, welcoming and comedy-loving people, so conversations will always have jokes thrown in.",
            "Jonathan Lucas, promotions specialist, Osaka",
          ],
        },
      ],
      media: [
        {
          type: "image",
          orientation: "portrait",
          src: "https://lonelyplanetstatic.imgix.net/marketing/best-in-travel/2025/categories/cities/osaka/sign-dumplings-gyoza-osaka-tourists-portrait.jpg",
          alt: "A store selling dumplings with large signage and people waiting outside",
          credit: "Rintaro Kanemoto for ������ϲʼ�ʱ����",
        },
        {
          type: "image",
          src: "https://lonelyplanetstatic.imgix.net/marketing/best-in-travel/2025/categories/cities/osaka/sign-dumplings-gyoza-osaka-tourists.jpg",
          alt: "A store selling dumplings with large signage and people waiting outside",
          credit: "Rintaro Kanemoto for ������ϲʼ�ʱ����",
        },
        {
          type: "image",
          orientation: "portrait",
          src: "https://lonelyplanetstatic.imgix.net/marketing/best-in-travel/2025/categories/cities/osaka/taiyaki-dish-pastry-hand-chef-portrait.jpg",
          alt: "A hand with a glove holding out a small fish pastry over a counter",
          credit: "Rintaro Kanemoto for ������ϲʼ�ʱ����",
        },
        {
          type: "image",
          src: "https://lonelyplanetstatic.imgix.net/marketing/best-in-travel/2025/categories/cities/osaka/taiyaki-dish-pastry-hand-chef.jpg",
          alt: "A hand with a glove holding out a small fish pastry over a counter",
          credit: "Rintaro Kanemoto for ������ϲʼ�ʱ����",
        },
        {
          type: "image",
          orientation: "portrait",
          src: "https://lonelyplanetstatic.imgix.net/marketing/best-in-travel/2025/categories/cities/osaka/poster-sign-running-man-osaka-tourists-portrait.jpg",
          alt: "A large billboard showing a running man with hands outraised, with tourists around",
          credit: "Rintaro Kanemoto for ������ϲʼ�ʱ����",
        },
        {
          type: "image",
          src: "https://lonelyplanetstatic.imgix.net/marketing/best-in-travel/2025/categories/cities/osaka/poster-sign-running-man-osaka-tourists.jpg",
          alt: "A large billboard showing a running man with hands outraised, with tourists around",
          credit: "Rintaro Kanemoto for ������ϲʼ�ʱ����",
        },
        {
          type: "image",
          orientation: "portrait",
          src: "https://lonelyplanetstatic.imgix.net/marketing/best-in-travel/2025/categories/cities/osaka/shrine-people-tourist-tree-portrait.jpg",
          alt: "A shrine shaped like a dragon mouth with people milling around outside",
          credit: "Rintaro Kanemoto for ������ϲʼ�ʱ����",
        },
        {
          type: "image",
          src: "https://lonelyplanetstatic.imgix.net/marketing/best-in-travel/2025/categories/cities/osaka/shrine-people-tourist-tree.jpg",
          alt: "A shrine shaped like a dragon mouth with people milling around outside",
          credit: "Rintaro Kanemoto for ������ϲʼ�ʱ����",
        },
        {
          type: "image",
          orientation: "portrait",
          src: "https://lonelyplanetstatic.imgix.net/marketing/best-in-travel/2025/categories/cities/osaka/children-observatory-cityscape-portrait.jpg",
          alt: "A cross section of a building with children looking out over a cityscape",
          credit: "Rintaro Kanemoto for ������ϲʼ�ʱ����",
        },
        {
          type: "image",
          src: "https://lonelyplanetstatic.imgix.net/marketing/best-in-travel/2025/categories/cities/osaka/children-observatory-cityscape.jpg",
          alt: "A cross section of a building with children looking out over a cityscape",
          credit: "Rintaro Kanemoto for ������ϲʼ�ʱ����",
        },
      ],
      articles: [
        "/articles/guide-to-osaka-japan",
        "/articles/best-road-trips-in-japan",
      ],
    },
    {
      name: "Curitiba",
      slug: "/brazil/the-south/curitiba",
      saily: "https://go.saily.site/aff_c?offer_id=101&aff_id=5957&url_id=1344",
      meta: {
        category: "cities",
        parent: "Brazil",
        tags: [],
        coords: {
          lat: -25.41806714,
          lon: -49.32194346,
        },
      },
      editorial: [
        {
          type: "p",
          children:
            "A city that values its citizens as well as its tourists, Curitiba is an immersive yet low-key experience. Hop on an e-bike to visit its singular sites, like the architectural gem that is Museu Oscar Niemeyer and one of the city’s largest parks, Barigui, that powers its facilities through a small hydroelectric plant with an artificial waterfall and lake.",
        },
        {
          type: "ol",
          children: [
            "Appreciate art, sculpture and an exhibit about architect Niemeyer at Museu Oscar Niemeyer, a striking museum that still looks modern 20 years after it opened.",
            "Smell the flowers in the Jardim Botânico, a slice of greenery with sculpture, water features, paths and an elegant greenhouse.",
            "Take a sublime train ride between Curitiba and Morretes through mountains and rainforest on the Serra Verde Express.",
          ],
        },
        {
          type: "blockquote",
          children: [
            "A city is only good for tourists if it’s good for its citizens. Curitiba always innovates and takes care of maintenance. Tourists don’t just visit tourist attractions, they experience our city.",
            "Tisa Kastrup, communication and curation, Curitiba Tourism",
          ],
        },
      ],
      media: [
        {
          type: "image",
          orientation: "portrait",
          src: "https://lonelyplanetstatic.imgix.net/marketing/best-in-travel/2025/categories/cities/curitiba/red-yellow-blue-train-forest-portrait.jpg",
          alt: "A shot of a red yellow blue painted train going through a forest with blue sky",
          credit: "Getty Images",
        },
        {
          type: "image",
          src: "https://lonelyplanetstatic.imgix.net/marketing/best-in-travel/2025/categories/cities/curitiba/red-yellow-blue-train-forest.jpg",
          alt: "A shot of a red yellow blue painted train going through a forest with blue sky",
          credit: "Getty Images",
        },
        {
          type: "image",
          orientation: "portrait",
          src: "https://lonelyplanetstatic.imgix.net/marketing/best-in-travel/2025/categories/cities/curitiba/bike-streets-trees-sunset-portrait.jpg",
          alt: "A bike rides away from the camera on a cobblestone street with buildings at sunset",
          credit: "Getty Images",
        },
        {
          type: "image",
          src: "https://lonelyplanetstatic.imgix.net/marketing/best-in-travel/2025/categories/cities/curitiba/bike-streets-trees-sunset.jpg",
          alt: "A bike rides away from the camera on a cobblestone street with buildings at sunset",
          credit: "Getty Images",
        },
        {
          type: "image",
          orientation: "portrait",
          src: "https://lonelyplanetstatic.imgix.net/marketing/best-in-travel/2025/categories/cities/curitiba/pagoda-leaves-pond-leaves-portrait.jpg",
          alt: "A pagoda in a city with a pond and leaves",
          credit: "Shutterstock",
        },
        {
          type: "image",
          src: "https://lonelyplanetstatic.imgix.net/marketing/best-in-travel/2025/categories/cities/curitiba/pagoda-leaves-pond-leaves.jpg",
          alt: "A pagoda in a city with a pond and leaves",
          credit: "Shutterstock",
        },
        {
          type: "image",
          orientation: "portrait",
          src: "https://lonelyplanetstatic.imgix.net/marketing/best-in-travel/2025/categories/cities/curitiba/interior-room-shadows-tables-art-portrait.jpg",
          alt: "Four tables with art pieces on them in an interior room with a curved ceiling and shadows",
          credit: "Alamy",
        },
        {
          type: "image",
          src: "https://lonelyplanetstatic.imgix.net/marketing/best-in-travel/2025/categories/cities/curitiba/interior-room-shadows-tables-art.jpg",
          alt: "Four tables with art pieces on them in an interior room with a curved ceiling and shadows",
          credit: "Alamy",
        },
        {
          _comment: "#5 mobile",
          type: "image",
          orientation: "portrait",
          src: "https://lonelyplanetstatic.imgix.net/marketing/best-in-travel/2025/categories/cities/curitiba/aerial-garden-geometric-building-portrait.jpg",
          alt: "Aerial shot of geometric gardens leading up to a glass building surrounded by trees with a city in the background",
          credit: "Alamy",
        },
        {
          _comment: "#5 desktop",
          type: "image",
          src: "https://lonelyplanetstatic.imgix.net/marketing/best-in-travel/2025/categories/cities/curitiba/aerodynamic-building-over-pool.jpg",
          alt: "A shot of a large aerodynamic building with a white ramp, people walking, reflective pool",
          credit: "Bernard Barroso/Shutterstock",
        },
      ],
      articles: [
        "/articles/guide-to-curitiba-brazil",
        "/articles/best-time-to-visit-brazil",
        "/articles/best-places-to-visit-in-brazil",
      ],
    },
    {
      name: "Palma de Mallorca",
      slug: "/spain/palma-de-mallorca",
      saily: "https://go.saily.site/aff_c?offer_id=101&aff_id=5957&url_id=1344",
      meta: {
        category: "cities",
        parent: "Spain",
        tags: [],
        coords: {
          lat: 39.56009431,
          lon: 2.96493615,
        },
      },
      editorial: [
        {
          type: "p",
          children:
            "Vibrant food and arts scenes, a rich local-crafts heritage and curious historical monuments collide in stylish Palma, on the sunny Balearic island of Mallorca. In 2025, this ancient, Mediterranean-hugging regional capital will debut a long-awaited, green-driven waterfront promenade. Sidestep the high season and discover how Palma (which is working to balance tourism and the needs of residents) has grown into a dynamic year-round destination.",
        },
        {
          type: "ol",
          children: [
            "Dive into the world of Catalan artist Joan Miró; the unmissable Fundació Miró Mallorca includes two studios, Son Boter and Taller Sert.",
            "Stay in a soulfully converted historic building, such as Palma Riad, Portella Palma or Can Cera.",
            "Join a two-wheel city tour or rent a bike and cycle along the seafront to further-flung beaches.",
          ],
        },
        {
          type: "blockquote",
          children: [
            "One of my favorite literary corners in Palma is El Terreno district. Wandering its steep narrow streets we follow in the footsteps of such authors as Gertrude Stein, DH Lawrence and Camilo José Cela.",
            "Marina Alonso De Caso, founder of La Salina bookshop, Santa Catalina",
          ],
        },
      ],
      media: [
        {
          type: "video",
          orientation: "landscape",
          credit: "Best In Travel 2025",
          src: {
            poster:
              "https://lonelyplanetstatic.imgix.net/marketing/best-in-travel/2025/videos/palma-de-mallorca/palma-de-mallorca-trailer-landscape.png",
            mp4: "https://lonelyplanetstatic.imgix.net/marketing/best-in-travel/2025/videos/palma-de-mallorca/palma-de-mallorca-trailer-landscape.mp4",
            webm: "https://lonelyplanetstatic.imgix.net/marketing/best-in-travel/2025/videos/palma-de-mallorca/palma-de-mallorca-trailer-landscape.webm",
            jwvid: "iSwWSPwK",
          },
        },
        {
          type: "video",
          orientation: "portrait",
          credit: "Best In Travel 2025",
          src: {
            poster:
              "https://lonelyplanetstatic.imgix.net/marketing/best-in-travel/2025/videos/palma-de-mallorca/palma-de-mallorca-trailer-portrait.png",
            mp4: "https://lonelyplanetstatic.imgix.net/marketing/best-in-travel/2025/videos/palma-de-mallorca/palma-de-mallorca-trailer-portrait.mp4",
            webm: "https://lonelyplanetstatic.imgix.net/marketing/best-in-travel/2025/videos/palma-de-mallorca/palma-de-mallorca-trailer-portrait.webm",
            jwvid: "6zDqkN2F",
          },
        },
        {
          type: "image",
          orientation: "portrait",
          src: "https://lonelyplanetstatic.imgix.net/marketing/best-in-travel/2025/categories/cities/palma-de-mallorca/trees-canal-archway-portrait.jpg",
          alt: "Trees on either side of a canal with an archway in the distance",
          credit: "Felipe Santibañez for ������ϲʼ�ʱ����",
        },
        {
          type: "image",
          src: "https://lonelyplanetstatic.imgix.net/marketing/best-in-travel/2025/categories/cities/palma-de-mallorca/trees-canal-archway.jpg",
          alt: "Trees on either side of a canal with an archway in the distance",
          credit: "Felipe Santibañez for ������ϲʼ�ʱ����",
        },
        {
          _comment: "#3 mobile",
          type: "image",
          orientation: "portrait",
          src: "https://lonelyplanetstatic.imgix.net/marketing/best-in-travel/2025/categories/cities/palma-de-mallorca/bicycle-sunlight-village-alleyway-portrait.jpg",
          alt: "A man bicycling in an alleyway with the sunlight behind him",
          credit: "Felipe Santibañez for ������ϲʼ�ʱ����",
        },
        {
          _comment: "#3 desktop",
          type: "image",
          src: "https://lonelyplanetstatic.imgix.net/marketing/best-in-travel/2025/categories/cities/palma-de-mallorca/boxes-dessert-cakes-glass.jpg",
          alt: "Red and white boxes for desserts on top of a pastry case with cakes",
          credit: "Felipe Santibañez for ������ϲʼ�ʱ����",
        },
        {
          type: "image",
          orientation: "portrait",
          src: "https://lonelyplanetstatic.imgix.net/marketing/best-in-travel/2025/categories/cities/palma-de-mallorca/lighthouse-ocean-rock-portrait.jpg",
          alt: "A lighthouse on a cliff with ocean and clouds",
          credit: "Felipe Santibañez for ������ϲʼ�ʱ����",
        },
        {
          type: "image",
          src: "https://lonelyplanetstatic.imgix.net/marketing/best-in-travel/2025/categories/cities/palma-de-mallorca/lighthouse-ocean-rock.jpg",
          alt: "A lighthouse on a cliff with ocean and clouds",
          credit: "Felipe Santibañez for ������ϲʼ�ʱ����",
        },
        {
          type: "image",
          orientation: "portrait",
          src: "https://lonelyplanetstatic.imgix.net/marketing/best-in-travel/2025/categories/cities/palma-de-mallorca/beach-chairs-building-ocean-portrait.jpg",
          alt: "People wading out of the ocean with rocks and beach chairs, with beach houses behind them",
          credit: "Felipe Santibañez for ������ϲʼ�ʱ����",
        },
        {
          type: "image",
          src: "https://lonelyplanetstatic.imgix.net/marketing/best-in-travel/2025/categories/cities/palma-de-mallorca/beach-chairs-building-ocean.jpg",
          alt: "People wading out of the ocean with rocks and beach chairs, with beach houses behind them",
          credit: "Felipe Santibañez for ������ϲʼ�ʱ����",
        },
      ],
      articles: [
        "/articles/guide-to-palma-de-mallorca",
        "/articles/where-to-eat-palma-de-mallorca",
        "/articles/mallorca-vs-ibiza",
      ],
    },
    {
      name: "Edmonton",
      slug: "/canada/alberta/edmonton",
      saily: "https://go.saily.site/aff_c?offer_id=101&aff_id=5957&url_id=1357",
      meta: {
        category: "cities",
        parent: "Canada",
        tags: [],
        coords: {
          lat: 53.5519705,
          lon: -113.5019278,
        },
      },
      editorial: [
        {
          type: "p",
          children:
            "Edmonton, Alberta, has long been celebrated for its fringe festival and bohemian Old Strathcona district. Today the city is doubling down on its downtown with the opening of the Ice District, the second-largest sports and entertainment complex in North America. Further enrich your visit by learning about Indigenous history at Royal Alberta Museum's Human History Hall.",
        },
        {
          type: "ol",
          children: [
            "Join the Edmonton International Fringe Festival for 11 days of live shows in parks, small theaters and on the streets.",
            "Enjoy arts, boutique shopping and multicultural food on Whyte Ave, the main artery of the Old Strathcona neighborhood.",
            "Peruse the province’s natural and cultural history at the Royal Alberta Museum, western Canada’s largest, refreshed and relocated in 2018.",
          ],
        },
        {
          type: "blockquote",
          children: [
            "Discover mom and pop gems in Chinatown, rent e-bikes in Old Strathcona to zip around North America’s largest stretch of urban river valley parkland.",
            "Linda Hoang, social media strategist",
          ],
        },
      ],
      media: [
        {
          type: "image",
          orientation: "portrait",
          src: "https://lonelyplanetstatic.imgix.net/marketing/best-in-travel/2025/categories/cities/edmonton/street-mural-building-portrait.jpg",
          alt: "A street with people walking under a building with a mural",
          credit: "Amber Bracken for ������ϲʼ�ʱ����",
        },
        {
          type: "image",
          src: "https://lonelyplanetstatic.imgix.net/marketing/best-in-travel/2025/categories/cities/edmonton/street-mural-building.jpg",
          alt: "A street with people walking under a building with a mural",
          credit: "Amber Bracken for ������ϲʼ�ʱ����",
        },
        {
          type: "image",
          orientation: "portrait",
          src: "https://lonelyplanetstatic.imgix.net/marketing/best-in-travel/2025/categories/cities/edmonton/neon-signs-building-sky-portrait.jpg",
          alt: "A row of neon signs attached to a building with cloudy sky behind them",
          credit: "Amber Bracken for ������ϲʼ�ʱ����",
        },
        {
          type: "image",
          src: "https://lonelyplanetstatic.imgix.net/marketing/best-in-travel/2025/categories/cities/edmonton/neon-signs-building-sky.jpg",
          alt: "A row of neon signs attached to a building with cloudy sky behind them",
          credit: "Amber Bracken for ������ϲʼ�ʱ����",
        },
        {
          type: "image",
          orientation: "portrait",
          src: "https://lonelyplanetstatic.imgix.net/marketing/best-in-travel/2025/categories/cities/edmonton/museum-skeleton-cars-windows-portrait.jpg",
          alt: "An interior of the museum with a skeleton and cars and windows",
          credit: "Amber Bracken for ������ϲʼ�ʱ����",
        },
        {
          type: "image",
          src: "https://lonelyplanetstatic.imgix.net/marketing/best-in-travel/2025/categories/cities/edmonton/museum-skeleton-cars-windows.jpg",
          alt: "An interior of the museum with a skeleton and cars and windows",
          credit: "Amber Bracken for ������ϲʼ�ʱ����",
        },
        {
          type: "image",
          orientation: "portrait",
          src: "https://lonelyplanetstatic.imgix.net/marketing/best-in-travel/2025/categories/cities/edmonton/conductor-train-tracks-trees-portrait.jpg",
          alt: "A rear view of the conductor with train tracks and a train behind them",
          credit: "Amber Bracken for ������ϲʼ�ʱ����",
        },
        {
          type: "image",
          src: "https://lonelyplanetstatic.imgix.net/marketing/best-in-travel/2025/categories/cities/edmonton/conductor-train-tracks-trees.jpg",
          alt: "A rear view of the conductor with train tracks and a train behind them",
          credit: "Amber Bracken for ������ϲʼ�ʱ����",
        },
        {
          type: "image",
          orientation: "portrait",
          src: "https://lonelyplanetstatic.imgix.net/marketing/best-in-travel/2025/categories/cities/edmonton/plate-food-salad-fork-portrait.jpg",
          alt: "A plate of vegetables and fruit with a fork and knife on a table",
          credit: "Amber Bracken for ������ϲʼ�ʱ����",
        },
        {
          type: "image",
          src: "https://lonelyplanetstatic.imgix.net/marketing/best-in-travel/2025/categories/cities/edmonton/plate-food-salad-fork.jpg",
          alt: "A plate of vegetables and fruit with a fork and knife on a table",
          credit: "Amber Bracken for ������ϲʼ�ʱ����",
        },
      ],
      articles: [
        "/articles/guide-to-edmonton",
        "/articles/best-indigenous-experiences-in-edmonton",
        "/articles/guide-to-alberta",
      ],
    },
  ],
};
