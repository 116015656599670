module.exports = {
  categories: [
    {
      name: "countries",
      media: [
        {
          type: "video",
          orientation: "landscape",
          src: {
            poster:
              "https://lonelyplanetstatic.imgix.net/marketing/best-in-travel/2025/videos/countries/best-in-travel-countries-trailer-landscape.png",
            mp4: "https://lonelyplanetstatic.imgix.net/marketing/best-in-travel/2025/videos/countries/best-in-travel-countries-trailer-landscape.mp4",
            webm: "https://lonelyplanetstatic.imgix.net/marketing/best-in-travel/2025/videos/countries/best-in-travel-countries-trailer-landscape.webm",
          },
        },
        {
          type: "video",
          orientation: "portrait",
          src: {
            poster:
              "https://lonelyplanetstatic.imgix.net/marketing/best-in-travel/2025/videos/countries/best-in-travel-countries-trailer-portrait.png",
            mp4: "https://lonelyplanetstatic.imgix.net/marketing/best-in-travel/2025/videos/countries/best-in-travel-countries-trailer-portrait.mp4",
            webm: "https://lonelyplanetstatic.imgix.net/marketing/best-in-travel/2025/videos/countries/best-in-travel-countries-trailer-portrait.webm",
          },
        },
      ],
      meta: {
        title: "Best Countries to Travel in 2025 - ������ϲʼ�ʱ����",
        description: "Find out our top 10 countries to travel to in 2025.",
        url: "/best-in-travel/countries",
        image:
          "https://lonelyplanetstatic.imgix.net/marketing/best-in-travel/2025/videos/countries/best-in-travel-countries-trailer-landscape.png",
        canonicalUrl: "/best-in-travel/countries",
      },
    },
    {
      name: "regions",
      media: [
        {
          type: "video",
          orientation: "landscape",
          src: {
            poster:
              "https://lonelyplanetstatic.imgix.net/marketing/best-in-travel/2025/videos/regions/best-in-travel-regions-trailer-landscape.png",
            mp4: "https://lonelyplanetstatic.imgix.net/marketing/best-in-travel/2025/videos/regions/best-in-travel-regions-trailer-landscape.mp4",
            webm: "https://lonelyplanetstatic.imgix.net/marketing/best-in-travel/2025/videos/regions/best-in-travel-regions-trailer-landscape.webm",
          },
        },
        {
          type: "video",
          orientation: "portrait",
          src: {
            poster:
              "https://lonelyplanetstatic.imgix.net/marketing/best-in-travel/2025/videos/regions/best-in-travel-regions-trailer-portrait.png",
            mp4: "https://lonelyplanetstatic.imgix.net/marketing/best-in-travel/2025/videos/regions/best-in-travel-regions-trailer-portrait.mp4",
            webm: "https://lonelyplanetstatic.imgix.net/marketing/best-in-travel/2025/videos/regions/best-in-travel-regions-trailer-portrait.webm",
          },
        },
      ],
      meta: {
        title: "Best Regions to Travel in 2025 - ������ϲʼ�ʱ����",
        description:
          "These are our picks for the best places to travel in 2025.",
        url: "/best-in-travel/regions",
        image:
          "https://lonelyplanetstatic.imgix.net/marketing/best-in-travel/2025/videos/regions/best-in-travel-regions-trailer-landscape.png",
        canonicalUrl: "/best-in-travel/regions",
      },
    },
    {
      name: "cities",
      media: [
        {
          type: "video",
          orientation: "landscape",
          src: {
            poster:
              "https://lonelyplanetstatic.imgix.net/marketing/best-in-travel/2025/videos/cities/best-in-travel-cities-trailer-landscape.png",
            mp4: "https://lonelyplanetstatic.imgix.net/marketing/best-in-travel/2025/videos/cities/best-in-travel-cities-trailer-landscape.mp4",
            webm: "https://lonelyplanetstatic.imgix.net/marketing/best-in-travel/2025/videos/cities/best-in-travel-cities-trailer-landscape.webm",
          },
        },
        {
          type: "video",
          orientation: "portrait",
          src: {
            poster:
              "https://lonelyplanetstatic.imgix.net/marketing/best-in-travel/2025/videos/cities/best-in-travel-cities-trailer-portrait.png",
            mp4: "https://lonelyplanetstatic.imgix.net/marketing/best-in-travel/2025/videos/cities/best-in-travel-cities-trailer-portrait.mp4",
            webm: "https://lonelyplanetstatic.imgix.net/marketing/best-in-travel/2025/videos/cities/best-in-travel-cities-trailer-portrait.webm",
          },
        },
      ],
      meta: {
        title: "Best Cities to Travel in 2025 - ������ϲʼ�ʱ����",
        description:
          "Check out the 10 best cities in 2025 on our annual Best in Travel list.",
        url: "/best-in-travel/cities",
        image:
          "https://lonelyplanetstatic.imgix.net/marketing/best-in-travel/2025/videos/cities/best-in-travel-cities-trailer-landscape.png",
        canonicalUrl: "/best-in-travel/cities",
      },
    },
  ],
};
